import React from "react";
import { Helmet } from "react-helmet";

import HeroSection from "./duskAccelerator/HeroSection";
import HowItWorks from "./duskAccelerator/HowItWorks";
import Packages from "./duskAccelerator/Packages";
import ApplyAccelerator from "./duskAccelerator/ApplyAccelerator";
import BrandTestimonial from "shared/BrandTestimonial";
import BrandLogoCarousel from "shared/BrandLogoCarousel";
import Stats from "./duskAccelerator/Stats";

const ForBrands = () => {
  return (
    <>
      <Helmet>
        <title>DUSK ACCELERATOR | DUSK</title>
      </Helmet>
      <HeroSection />
      <HowItWorks />
      <Packages />
      <Stats />
      <ApplyAccelerator />
      <BrandTestimonial />
      <BrandLogoCarousel />
    </>
  );
};

export default ForBrands;
