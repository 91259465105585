import React from "react";
import { TertiaryTitleText, NumberHeading } from "shared/typography/Typography";
import Compass from "assets/images/compass.gif";
import Drink from "assets/images/drink.gif";
import Star from "assets/images/star.gif";

import "./HowItWorks.scss";

const Items = [
  {
    src: Drink,
    count: 1,
    description: "YOUR PERFECT SERVE VISIBLE TO 800K+ CONSUSUMERS IN YOUR PARTNER VENUES",
    alt: "YOUR PERFECT SERVE VISIBLE TO 800K+ CONSUSUMERS IN YOUR PARTNER VENUES",
    id: "perfect-serve",
  },
  {
    src: Compass,
    count: 2,
    description: "CONSUMERS USE DUSK TO DISCOVER THE BEST BARS AND TRY A NEW DRINK EVERYDAY FOR FREE",
    alt: "CONSUMERS USE DUSK TO DISCOVER THE BEST BARS AND TRY A NEW DRINK EVERYDAY FOR FREE",
    id: "compass",
  },
  {
    src: Star,
    count: 3,
    description:
      "CONSUMERS PROVIDE INVALUABLE FEEDBACK, CREATE CONTENT AND REPURCHASE THE PRODUCTS",
    alt: "CONSUMERS PROVIDE INVALUABLE FEEDBACK, CREATE CONTENT AND REPURCHASE THE PRODUCTS",
    id: "star",
  },
];

const Item = ({ id, count, src, alt, description }) => (
  <div
    className="column is-3 is-6-mobile"
    style={{ padding: "0 2rem 1rem 2rem" }}
  >
    <NumberHeading style={{ marginBottom: "12px" }}>{count}</NumberHeading>
    <img id={id} src={src} alt={alt} className="how-it-works-image mb-2" />
    <p
      className="dusk-p-14 has-text-dark px-2 is-hidden-mobile"
      style={{ maxWidth: 200, margin: "0 auto" }}
    >
      {description}
    </p>
    <p className="dusk-medium-p-12 has-text-dark is-hidden-tablet">
      {description}
    </p>
  </div>
);

const HowItWorks = () => {
  return (
    <div className="container-how dusk-bg-light">
      <div className="container">
        <TertiaryTitleText style={{ marginBottom: "12px" }}>
          HOW IT WORKS
        </TertiaryTitleText>
        <div className="columns is-flex" style={{ flexWrap: "wrap", justifyContent: "center" }}>
          {Items.map((each) => (
            <Item key={each.count} {...each} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
