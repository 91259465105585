import React from "react";
import Ticker from "react-ticker";

/* prettier-ignore */
const Brands = [
  { title: "ABG", src: require("assets/images/barLogoABG.png") },
  { title: "Ballie", src: require("assets/images/barLogoBallie.png") },
  { title: "Bounce", src: require("assets/images/barLogoBounce.png") },
  { title: "Box Park", src: require("assets/images/barLogoBoxpark.png") },
  { title: "Brew Dog", src: require("assets/images/barLogoBrewdog.png") },
  { title: "Bussey Rooftop Bar", src: require("assets/images/barLogoBussey.png") },
  { title: "The Draft House", src: require("assets/images/barLogoDraft.png") },
  { title: "Grind", src: require("assets/images/barLogoGrind.png") },
  { title: "Kaki Kane", src: require("assets/images/barLogoKakikane.png") },
  { title: "Laine's", src: require("assets/images/barLogoLaines.png") },
  { title: "Meat Liquor", src: require("assets/images/barLogoMeat.png") },
  { title: "Near & Far", src: require("assets/images/barLogoNear&far.png") },
  { title: "Simmons", src: require("assets/images/barLogoSimmons.png") },
];

const Carousel = () => {
  return (
    <Ticker speed={10}>
      {({ index }) => (
        <div
          key={index}
          className="is-flex is-align-items-center is-justify-content-center px-3"
        >
          <img
            style={{
              width: 100,
              height: 100,
              objectFit: "contain",
              objectPosition: "center",
            }}
            src={Brands[index % Brands.length].src}
            alt={Brands[index % Brands.length].title}
          />
        </div>
      )}
    </Ticker>
  );
};

const BarLogoCarousel = () => {
  return (
    <div className="py-3 bg-black has-text-centered">
      <Carousel />
    </div>
  );
};

export default BarLogoCarousel;
