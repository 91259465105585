import React, { PureComponent } from "react";
import _ from "lodash";
import { apiGetRedirect } from "../shared/utils";
import NotFound from "./NotFound";

export default class Redirect extends PureComponent {
  state = {
    notFound: false
  };
  componentDidMount() {
    const slug = _.get(this.props, "match.params.slug");
    console.log("SLUG!", slug);
    if (slug) {
      apiGetRedirect(slug)
        .then(({ data }) => {
          window.location.href = data.url;
        })
        .catch(err => {
          this.setState({ notFound: true });
        });
    }
  }
  render() {
    if (this.state.notFound) return <NotFound />;

    return <div />;
  }
}
