import React from "react";
import "./Hero.scss";
import HeroBackgroundGradient from "./HeroBackgroundGradient";

const Hero = props => {
  const sectionStyles = {
    position: "relative"
  };

  if (props.background) {
    sectionStyles.background = `url(${props.background}) center no-repeat`;
    sectionStyles.backgroundSize = "cover";
  }
  return (
    <section
      className={
        "hero" +
        (props.fullHeight ? ` full-height` : "") +
        (props.size ? ` is-${props.size}` : "") +
        (props.className ? ` ${props.className}` : "")
      }
      style={sectionStyles}
    >
      {props.underlay ? <div className="is-hidden-tablet underlay" /> : null}
      {props.fade ? (
        <HeroBackgroundGradient sideFade={props.sideFade || null} />
      ) : null}

      <div className={"hero-body" + (props.bottomAlign ? " bottom-align" : "")}>
        <div className="container">{props.children}</div>
      </div>
    </section>
  );
};

export default Hero;
