import React from "react";
import NewHero from "shared/NewHero";
import { HeroButton } from "shared/Button";
import PhoneFrameImage from "shared/PhoneFrameImage";
import "./HeroSection.scss";

const HeroSection = ({ featureRef }) => {
  const goToFeature = () => {
    featureRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <NewHero size="large">
      <div className="columns">
        <div className="column is-5-desktop is-6">
          <h1 className="dusk-h1-outline-100 dusk-m-bot">
            LOYALTY DONE RIGHT, <span className="has-text-white">FINALLY</span>
          </h1>
          <p className="dusk-p-18 mb-3">
            DUSK users get rewarded for spending in your venue.
            <br />
            <br />
            No staff training, no loyalty cards, no stamps. Just seamless
            loyalty, finally.
          </p>
          <div className="has-text-centered-mobile">
            <HeroButton onClick={goToFeature}>Request our deck</HeroButton>
          </div>
        </div>
        <div
          className="column is-offset-3-desktop is-4-desktop is-6 is-flex image-control"
          style={{ padding: "0.75rem 2.75rem 0" }}
        >
          <PhoneFrameImage
            className="hero-image"
            src={require("../../assets/images/barPagePoints.png")}
            alt="app get drinks"
          />
        </div>
      </div>
    </NewHero>
  );
};

export default HeroSection;
