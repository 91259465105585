import { useMediaQuery } from "react-responsive";

const TabletOnwards = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768 });
  return isTablet ? children : null;
};

const UpToTablet = ({ children }) => {
  const isTablet = useMediaQuery({ maxWidth: 768 });
  return isTablet ? children : null;
};

export { TabletOnwards, UpToTablet };
