import React from "react";

const MultiImageBanner = ({
  title,
  children,
  columnClass = "",
  columnContainerClass = "",
}) => (
  <section className="section has-background-grey-darker">
    <div className="container">
      {title ? (
        <h1 className="dusk-h1-outline-80 mb-2 dusk-m-bot has-text-centered">
          {title}
        </h1>
      ) : null}
      <div className={`columns is-vcentered ${columnContainerClass}`}>
        {children.map((img, index) => (
          <div
            className={`column has-text-centered ${columnClass}`}
            key={index}
          >
            {img}
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default MultiImageBanner;
