import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

import "./Tracking.scss";
import IntroContent from "./IntroContent";
import NotFound from "../NotFound";
import { apiGetTrackingBarInfo } from "../../shared/utils";
import TrackingForm from "./TrackingForm";
import CheckedIn from "./CheckedIn";

const TrackCustomer = ({ match }) => {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [barInfo, setBarInfo] = useState(null);
  const [is404, setIs404] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showCheckedIn, setShowCheckedIn] = useState(false);

  const slug = match && _.get(match, "params.slug");

  useEffect(() => {
    if (!slug) {
      setIs404(true);
    } else {
      setIsLoading(true);
      apiGetTrackingBarInfo(slug)
        .then(({ data: { data } }) => {
          setBarInfo(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setIs404(true);
        });
    }
  }, [slug]);

  if (is404) {
    return <NotFound />;
  }

  const onIntroProceedClick = () => {
    setShowForm(true);
  };

  const onFormSubmit = () => {
    setShowForm(false);
    setShowCheckedIn(true);
  };

  const onResetClick = () => {
    setShowForm(false);
    setShowCheckedIn(false);
  };

  return (
    <Fragment>
      <Helmet>
        <title>DUSK Track &amp; Trace</title>
      </Helmet>
      <div id="track-trace-container">
        {!showForm && !showCheckedIn && barInfo && (
          <IntroContent
            barInfo={barInfo}
            onProceedClick={onIntroProceedClick}
          />
        )}
        {!showCheckedIn && showForm && (
          <TrackingForm slug={slug} onSubmit={onFormSubmit} />
        )}
        {showCheckedIn && barInfo && (
          <CheckedIn barInfo={barInfo} onReset={onResetClick} />
        )}
      </div>
    </Fragment>
  );
};

export default TrackCustomer;
