import React from "react";
import { TertiaryTitleText, NumberHeading } from "./typography/Typography";
import LinkSafely from "assets/images/linkSafely.gif";
import Compass from "assets/images/compass.gif";
import Drink from "assets/images/drink.gif";
import Star from "assets/images/star.gif";

import "./HowItWorks.scss";

const Items = [
  {
    src: Compass,
    count: 1,
    description: "DISCOVER THE COOLEST BARS & REDEEM A FREE DRINK EVERY NIGHT",
    alt: "compass",
    id: "compass",
  },
  {
    src: LinkSafely,
    count: 2,
    description: "LINK ANY CARD SAFELY & SECURELY ON DUSK",
    alt: "link safely",
    id: "link",
  },
  {
    src: Drink,
    count: 3,
    description: "EARN POINTS EVERY TIME YOU SPEND ON FOOD & DRINK WITH YOUR CARD",
    alt: "martini",
    id: "martini",
  },
  {
    src: Star,
    count: 4,
    description: "USE POINTS TO UNLOCK EXCLUSIVE REWARDS",
    alt: "star",
    id: "star",
  },
];

const Item = ({ id, count, src, alt, description }) => (
  <div
    className="column is-3 is-6-mobile"
    style={{ padding: "0 2rem 1rem 2rem" }}
    // style={{ paddingBottom: "1rem" }}
  >
    <NumberHeading style={{ marginBottom: "12px" }}>{count}</NumberHeading>
    <img id={id} src={src} alt={alt} className="how-it-works-image mb-2" />
    <p
      className="dusk-p-14 has-text-dark px-2 is-hidden-mobile"
      style={{ maxWidth: 200, margin: "0 auto" }}
    >
      {description}
    </p>
    <p className="dusk-medium-p-12 has-text-dark is-hidden-tablet">
      {description}
    </p>
  </div>
);

const HowItWorks = () => {
  return (
    <div className="container-how dusk-bg-light">
      <div className="container">
        <TertiaryTitleText style={{ marginBottom: "12px" }}>
          HOW IT WORKS
        </TertiaryTitleText>
        <div className="columns is-flex" style={{ flexWrap: "wrap" }}>
          {Items.map((each) => (
            <Item key={each.count} {...each} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
