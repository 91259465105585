import React from "react";

import "./UserTestimonialSection.scss";

const Testimonials = [
  {
    review:
      "“Used this app many times when going out to London, I really don't know how I survived beforehand!”",
    rating: "★★★★★ by Louis9712",
  },
  {
    review:
      "“Seriously, the cheapness and ability to find dope places is so good”",
    rating: "★★★★★ by 3N11",
  },
  {
    review: "“This app is one of the best things about living in London”",
    rating: "★★★★★ by DanKeel",
  },
  {
    review:
      "“Loving this app, me and my friends are trying new bars and lots of new exciting drinks I would highly recommend getting it”",
    rating: "★★★★★  by Mermaid-1303",
  },
  {
    review:
      "“Best thing since sliced bread! This app makes a night out that more unique, affordable and of course FUN!”",
    rating: "★★★★★  by Mareefa92",
  },
  {
    review:
      "“Top app. Love the concept and the app is easy to navigate with lots of fun interactive elements! A must have app for Londoners!”",
    rating: "★★★★★  by dinocharl1",
  },
  {
    review:
      "“Amazing! This is a beautiful app that encourages exploration, making new friends and trying something new. It's so easy to get stuck in a rut of drinking at the same place.”",
    rating: "★★★★★  by Georgemills97",
  },
];

const UserTestimonialCard = ({ review, rating }) => {
  return (
    <div className="testimonial-card bg-white py-5">
      <div className="card-content has-text-black">
        <p className="dusk-medium-p-20-12 mb-2">{review}</p>
        <p className="dusk-p-20">{rating}</p>
      </div>
    </div>
  );
};

const UserTestimonialSection = () => {
  return (
    <div className="section section-testimonial bg-black">
      <div className="container-testimonial is-flex">
        {Testimonials.map((each, index) => (
          <UserTestimonialCard
            key={index}
            review={each.review}
            rating={each.rating}
          />
        ))}
      </div>
    </div>
  );
};

export default UserTestimonialSection;
