import React from "react";
import cx from "classnames";
import FrameShadow from "assets/images/iPhoneFrameDarkShadow.png";
import Frame from "assets/images/iPhoneFrameDark.png";
import "./PhoneFrameImage.scss";

const PhoneFrameImage = ({ src, alt, shadow, className, ...props }) => {
  const style = {
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
  };
  if (shadow) {
    style.backgroundImage = `url(${FrameShadow})`;
    style.backgroundSize = "100%";
  } else {
    style.backgroundImage = `url(${Frame})`;
    style.backgroundSize = "100%";
  }
  return (
    <div
      className={cx("phone-frame", shadow ? "shadow" : undefined, className)}
      {...props}
    >
      <div className="frame" style={style} />
      <img
        src={src}
        alt={alt}
        className={cx("frame-underlay", shadow ? "shadow" : undefined)}
      />
    </div>
  );
};

export default PhoneFrameImage;
