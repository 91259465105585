import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import HowItWorks from "shared/HowItWorks";
import FeaturedIn from "shared/FeaturedIn";

import Stats from "./forBars/Stats";
import HeroSection from "./forBars/HeroSection";
import FeatureSection from "./forBars/FeatureSection";
import TestimonialSection from "./forBars/TestimonialSection";
import FeatureYourBar from "./forBars/FeatureYourBar";
import BarLogoCarousel from "shared/BarLogoCarousel";

const ForBars = () => {
  const featureRef = useRef();
  return (
    <>
      <Helmet>
        <title>For Bars | DUSK</title>
      </Helmet>
      <HeroSection featureRef={featureRef} />
      <HowItWorks />
      <FeatureSection />
      <Stats />
      <TestimonialSection />
      <BarLogoCarousel />
      <FeatureYourBar innerRef={featureRef} />
      <FeaturedIn />
    </>
  );
};

export default ForBars;
