import React from "react";
import cx from "classnames";

import "./Button.scss";
import { ButtonText } from "./typography/Typography";

const Button = ({ small, large, children, className, ...props }) => (
  <button
    className={cx(
      "button is-primary is-dusk",
      small ? "is-small" : undefined,
      large ? "is-large" : undefined,
      className
    )}
    {...props}
  >
    <ButtonText small={small}>{children}</ButtonText>
  </button>
);

export const HeroButton = ({ children, className, ...props }) => (
  <button className={cx("button is-dusk is-hero", className)} {...props}>
    {children}
  </button>
);

export const RoundedButton = ({
  size,
  large,
  children,
  className,
  ...props
}) => (
  <button
    className={cx(
      "button is-dusk is-rounded",
      size ? `is-${size}` : undefined,
      className
    )}
    {...props}
  >
    {children}
  </button>
);

export default Button;
