import React from "react";
import PhoneFrameImage from "shared/PhoneFrameImage";
import { DarkSection } from "shared/Section";
import DrinkImage from "assets/images/homePageDrink.png";

const DrinkSection = () => {
  return (
    <DarkSection>
      <div className="columns section-dusk-columns">
        <div className="column is-5 is-offset-1 section-dusk-text-column has-text-centered-mobile">
          <div style={{ maxWidth: 380 }}>
            <h1 className="dusk-h1-160 has-text-white">
              DRINK.
            </h1>
            <p className="dusk-medium-p-20-14">
              Find the best bars in your city and plan the perfect night out with our curated guides.
            </p>
            <p className="dusk-p-20">
              GET FREE DRINKS DAILY AND EARN POINTS WHEN YOU SPEND.
            </p>
          </div>
        </div>
        <div className="column is-6 section-dusk-image-column">
          <div className="section-dusk-image-container">
            <PhoneFrameImage
              shadow
              className="image"
              src={DrinkImage}
              alt="app get drinks"
            />
          </div>
        </div>
      </div>
    </DarkSection>
  );
};

export default DrinkSection;
