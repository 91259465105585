import React from "react";
import Image from "../../shared/Image";

const TrackLogo = () => (
  <Image
    className="track-logo"
    srcs={[
      require("../../assets/images/logoTrackTrace.png"),
      require("../../assets/images/logoTrackTrace@2x.png"),
      require("../../assets/images/logoTrackTrace@3x.png"),
    ]}
    alt="DUSK - Get rewarded for going out"
    width={175}
    height={47}
  />
);

const TrackingHeader = () => (
  <nav className="navbar track-trace" role="navigation">
    <div className="navbar-brand">
      {/* eslint-disable-next-line */}
      <a className="navbar-item">
        <TrackLogo />
      </a>
    </div>
  </nav>
);

export default TrackingHeader;
