import React, { useCallback, useState } from "react";
import cx from "classnames";
import "./BrandTestimonial.scss";

/* prettier-ignore */
const brands = [
  { logo: require('assets/images/brandLogoRedbull.png'), title: 'Red Bull', testimonial: 'DUSK is by far the most effective way we\'ve found to sample variants of our products in bars we just couldn\'t access otherwise.', name: 'Rich Levinson', position: 'On-Premise Marketing Manager' },
  { logo: require('assets/images/brandLogoBacardi.png'), title: 'Bacardi', testimonial: 'Working with DUSK has allowed us to make our activation truly digital, something most agencies struggle with. It\'s also refreshing working with a team that understand the value of driving trial; consumer data and targeted footfall management.', name: 'Andrew Tindall', position: 'UK Activation Manager' },
  { logo: require('assets/images/brandLogoWilliam.png'), title: 'William Grant & Sons', testimonial: 'DUSK made it easy for us to try a new serve with our millennial target audience. I was impressed at the feedback and data we got back.', name: 'James Taylor', position: 'Senior Brand Manager' },
]

const BrandTestimonial = () => {
  const [activeTestimonial, setActiveTestimonial] = useState(1);
  const TestimonialContainer = useCallback(() => {
    return (
      <div className="brand-testimonial-text">
        <p className="dusk-medium-p-20-14 has-text-centered is-hidden-mobile">
          {brands[activeTestimonial].testimonial}
          <br />
          <br />
          <strong className="has-text-dark">
            {`${brands[activeTestimonial].name}, ${brands[activeTestimonial].position}`}
          </strong>
        </p>
        <p className="dusk-light-p-16 has-text-centered is-hidden-tablet">
          {brands[activeTestimonial].testimonial}
          <br />
          <br />
          <strong className="has-text-dark">
            {`${brands[activeTestimonial].name}, ${brands[activeTestimonial].position}`}
          </strong>
        </p>
      </div>
    );
  }, [activeTestimonial]);
  return (
    <div className="section dusk-bg-light">
      <div className="container mx-sm-3">
        <h1 className="dusk-h1-80 has-text-centered">TESTIMONIALS</h1>
        <div className="columns is-mobile my-3">
          {brands.map((each, index) => (
            <div
              className="column is-flex is-justify-content-center"
              key={each.title}
              onClick={() => setActiveTestimonial(index)}
            >
              <img
                src={each.logo}
                alt={each.title}
                className={cx(
                  "brand-testimonial-logo",
                  index === activeTestimonial ? "active" : undefined
                )}
              />
            </div>
          ))}
        </div>
        <TestimonialContainer />
      </div>
    </div>
  );
};

export default BrandTestimonial;
