import React from "react";
import Columns from "shared/Columns";
import { UpToTablet, TabletOnwards } from "shared/MediaQuery";
import "./Stats.scss";

const points = [
  {
    src: require("assets/images/iconCake.gif"),
    title: require("assets/images/number24.png"),
    body: "Average User Age",
  },
  {
    src: require("assets/images/iconHeartComment.gif"),
    // title: "91%",
    title: require("assets/images/number91.png"),
    body: "Millenial & Gen-z database",
  },
  {
    src: require("assets/images/iconFemaleSign.png"),
    // title: "56%",
    title: require("assets/images/number56.png"),
    body: "Female Audience",
  },
  {
    src: require("assets/images/iconClouds.gif"),
    // title: "83%",
    title: require("assets/images/number83.png"),
    body: "using Dusk on their night out",
  },
];

const StatColumn = ({ src, title, body }) => (
  <div
    className="column is-3 is-6-mobile has-text-centered"
    style={{ padding: "0 2rem 1rem 2rem" }}
  >
    <img
      src={src}
      alt={body}
      width={64}
      height={64}
      style={{ marginBottom: "15px" }}
    />
    <img
      src={title}
      alt={`${body} count`}
      className="stats-title"
      style={{ display: "block", marginBottom: "15px" }}
    />
    <UpToTablet>
      <p className="dusk-condensed-p-16 has-text-dark is-uppercase px-2">
        {body}
      </p>
    </UpToTablet>
    <TabletOnwards>
      <p className="dusk-condensed-p-30 has-text-dark is-uppercase px-2">
        {body}
      </p>
    </TabletOnwards>
  </div>
);

const Stats = () => (
  <Columns
    innerClassName="dusk-bg-light"
    className="is-flex"
    columnProps={{
      style: {
        flexWrap: "wrap",
      },
    }}
  >
    {points.map((point) => (
      <StatColumn key={point.title} {...point} />
    ))}
  </Columns>
);

export default Stats;
