import React, { Fragment, PureComponent } from "react";
import Hero from "../shared/Hero";
import EditDetail from "./editPage/EditDetail";
// import MobileBottomButton from "../shared/MobileBottomButton";
import Columns from "../shared/Columns";
import ListingImage from "../shared/ListingImage";
import GetTheApp from "../shared/GetTheApp";
import Loading from "../shared/Loading";
import { TitleText } from "../shared/typography/Typography";

import { withRouter } from "react-router-dom";

import { Helmet } from "react-helmet";

import { apiGetEdit } from "../shared/utils";

class EditPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      data: null
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { location, slug }
      }
    } = this.props;
    apiGetEdit(`${location}/${slug}`)
      .then(result => {
        this.setState({
          data: result.data,
          isLoading: false,
          error: result.status !== 200 ? "We couldn't find that bar..." : null
        });
      })
      .catch(error => {
        if (error.response) {
          this.setState({
            error: error.response.data.fail || "We couldn't find that bar...",
            isLoading: false,
            data: null
          });
        } else {
          this.setState({
            error: "We couldn't find that bar...",
            isLoading: false,
            data: null
          });
        }
      });
  }

  render() {
    // TO DO update when confirmed if view in dusk button should be included
    const { isLoading, data, error } = this.state;
    if (isLoading) return <Loading />;
    if (error)
      return (
        <Fragment>
          <Helmet>
            <title>404 | DUSK</title>
          </Helmet>
          <Hero background={null} fade bottomAlign>
            <TitleText className="dusk-m-bot-full">404</TitleText>
          </Hero>
          <section className="section">
            <div className="container">{error}</div>
          </section>
        </Fragment>
      );
    const {
      // edit: { title, share_url, image_url, entries }
      edit: { title, image_url, entries }
    } = data;
    return (
      <Fragment>
        <Helmet>
          <title>{title || "'For The Gram'"} | DUSK</title>
        </Helmet>

        {/* <Hero background={image_url} fullHeight fade bottomAlign> */}
        <Hero background={image_url} fade bottomAlign>
          <TitleText className="dusk-m-bot-full">{title}</TitleText>
        </Hero>
        <Fragment>
          {/* <MobileBottomButton
            to={share_url || "/"}
          /> */}
          <EditDetail data={data} />
          {entries.length ? (
            <Columns isMultiline>
              {entries.map((each, index) => (
                <div
                  className={`column has-text-centered is-one-third is-half-tablet padding-special`}
                  key={index}
                >
                  <ListingImage
                    images={[each.image_url, each.image_url, each.image_url]}
                    name={each.name}
                    description={each.summary}
                    slug={each.slug}
                    tags={each.tags}
                  />
                </div>
              ))}
            </Columns>
          ) : null}
        </Fragment>
        <GetTheApp mobileReversal={true} />
      </Fragment>
    );
  }
}

export default withRouter(EditPage);
