import React from "react";
import Ticker from "react-ticker";

const Places = ["LONDON", "BIRMINGHAM", "MANCHESTER", "LIVERPOOL", "BRIGHTON", "LEEDS", "BRISTOL", "NOTTINGHAM", "CARDIFF", "SHEFFIELD", "NEWCASTLE"];

const Carousel = () => {
  return (
    <Ticker speed={10}>
      {({ index }) => (
        <p key={index} className="dusk-condensed-p-50 py-5 px-3">
          {Places[index % Places.length]}
        </p>
      )}
    </Ticker>
  );
};

const LocationCarouselSection = () => {
  return (
    <div className="section bg-black has-text-centered">
      <p className="dusk-p-20">WE ARE LIVE IN</p>
      <Carousel />
      <p className="dusk-p-20">DROPPING IN MORE CITIES SOON</p>
    </div>
  );
};

export default LocationCarouselSection;
