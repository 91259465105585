import React from "react";
import { TabletOnwards, UpToTablet } from "shared/MediaQuery";

const TestimonialSection = () => {
  return (
    <div
      className="section dusk-bg-dark"
      style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
    >
      <div className="container has-text-centered is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <p className="dusk-medium-p-25 mb-2">
          “Since partnering with DUSK, Simmons has seen a major boost in
          footfall and increased spend per head. There’s no easier way to get a
          targeted audience into your bar.”
        </p>
        <UpToTablet>
          <p className="dusk-condensed-p-20">
            NICK CAMPBELL, OWNER OF SIMMONS BARS
          </p>
        </UpToTablet>
        <TabletOnwards>
          <p className="dusk-condensed-p-30" style={{ lineHeight: 1.33 }}>
            NICK CAMPBELL, OWNER OF SIMMONS BARS
          </p>
        </TabletOnwards>
      </div>
    </div>
  );
};

export default TestimonialSection;
