import React from "react";
import LoadingGIF from "../assets/images/loader.gif";
import "./Loading.scss";

const Loading = ({ children, className, ...props }) => (
  <section
    className={"section loading" + (className ? ` ${className}` : "")}
    {...props}
  >
    <img
      className="loading-image"
      src={LoadingGIF}
      alt="DUSK - Cat lazying while playing with ball"
    />
  </section>
);

export default Loading;
