import React from "react";
import PhoneFrameImage from "shared/PhoneFrameImage";
import { LightSection } from "shared/Section";

const EngagementSection = () => {
  return (
    <LightSection className="mobile-small">
      <div className="is-hidden-tablet">
        <h1 className="dusk-h1-80 mb-3 has-text-centered">
          TRACK ENGAGEMENT & INCREASE SALES
        </h1>
      </div>
      <div className="columns section-dusk-columns">
        <div className="column is-5 is-offset-1 section-dusk-text-column has-text-centered-mobile is-hidden-mobile">
          <h1 className="dusk-h1-80">TRACK ENGAGEMENT & INCREASE SALES</h1>
        </div>
        <div className="column is-6 section-dusk-image-column">
          <div className="section-dusk-image-container">
            <PhoneFrameImage
              shadow
              className="image"
              src={require("assets/images/rewardPageDetail.png")}
              alt="app get drinks"
            />
          </div>
        </div>
      </div>
    </LightSection>
  );
};

export default EngagementSection;
