import React, { Fragment } from "react";
import Columns from "./Columns";

import "./ImageInputBlock.css";
import { ParagraphText } from "./typography/Typography";
import Image from "./Image";

const ImageInputBlock = ({
  align = "centered",
  children,
  className,
  images,
  alt,
  title,
  body,
  mobileReversal,
}) => (
  <Columns className={className} isCentered={true}>
    <div
      className={
        "column is-two-fifths has-text-centered" +
        (mobileReversal ? " is-hidden-mobile" : "")
      }
    >
      <Image srcs={images} alt={alt} />
    </div>
    <div className={`column image-input has-text-centered`}>
      <div className={`has-text-${align}`}>
        <h1 className="dusk-h1-outline-100">{title}</h1>
        {/* <HeroTextSmall className="dusk-half-m-bot">{title}</HeroTextSmall> */}
        {typeof body === "object" ? (
          <Fragment>
            <ParagraphText className="dusk-m-bot is-hidden-mobile">
              {body.default}
            </ParagraphText>
            <ParagraphText className="dusk-m-bot is-hidden-tablet">
              {body.mobile}
            </ParagraphText>
          </Fragment>
        ) : (
          <ParagraphText className="dusk-m-bot">{body}</ParagraphText>
        )}
      </div>
      {children}
    </div>
    {mobileReversal ? (
      <div className="column is-two-fifths has-text-centered is-hidden-tablet">
        <Image srcs={images} alt={alt} />
      </div>
    ) : null}
  </Columns>
);

export default ImageInputBlock;
