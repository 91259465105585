import React from "react";
import Site from "./layout/Site";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Content from "./layout/Content";
import Router from "./layout/Router";
import { Switch, Route } from "react-router-dom";
import TrackingHeader from "./covidTracking/TrackingHeader";

const Layout = () => (
  <Site>
    <Switch>
      <Route path="/tracking/:slug">
        <TrackingHeader />
      </Route>
      <Route>
        <Header />
      </Route>
    </Switch>
    <Content>
      <Router />
    </Content>
    <Switch>
      <Route path="/tracking/:slug">{/* NADA */}</Route>
      <Route>
        <Footer />
      </Route>
    </Switch>
  </Site>
);

export default Layout;
