import React, { useMemo, useState } from "react";
import cx from "classnames";
import Columns from "shared/Columns";
import { InputField } from "shared/Input";
import { validateEmail, apiCallNewsletterSignup } from "shared/utils";
import SubmitButton from "shared/SubmitButton";
import CitySelect from "app/home/CitySelect";
import IconError from "assets/images/inputIconError.svg";

const NewsletterSignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [error, setError] = useState("");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const validateForm = () => {
    if (sending || sent) return false;
    if (!firstName) {
      setError("Please enter your first name.");
      return;
    }
    if (!email || !validateEmail(email)) {
      setError("Please enter a valid email.");
      return;
    }
    if (!city) {
      setError("Please enter a city.");
      return;
    }
    return true;
  };

  const submitForm = () => {
    if (validateForm()) {
      setError("");
      setSending(true);
      apiCallNewsletterSignup({
        first_name: firstName,
        email,
        city: city,
      }).then(
        () => {
          setFirstName("");
          setEmail("");
          setCity("");
          setSending(false);
          setSent(true);
        },
        err => {
          setSending(false);
          setSent(false);
          setError("An unknown error has occurred. Please try again later.");
        }
      );
    }
  };

  const buttonClass = useMemo(() => {
    if (sending) {
      return "is-loading";
    } else if (sent) {
      return "is-success";
    } else {
      return "";
    }
  }, [sending, sent]);

  const buttonText = useMemo(() => {
    if (sending) {
      return "";
    } else if (sent) {
      return "Subscribed";
    } else {
      return "I want more";
    }
  }, [sending, sent]);

  return (
    <Columns innerClassName="container-how dusk-bg-light">
      <div className="column is-offset-one-fifth is-three-fifths has-text-centered">
        <div className="mx-3">
          <h1 className="dusk-h1-80 mb-2">LET'S GO OUT</h1>
          <p className="dusk-p-16 mb-2">
            Subscribe to our newsletter to receive early access to new drinks,
            secret spots, exclusive events and love notes from DUSK.
          </p>
          <InputField
            inputType="firstName"
            value={firstName}
            onChange={evt => setFirstName(evt.target.value)}
            dark
          />
          <InputField
            inputType="email"
            value={email}
            onChange={evt => setEmail(evt.target.value)}
            dark
          />
          <CitySelect selectedCity={city} onChange={value => setCity(value)} />
          {error ? (
            <p className="dusk-p-error-14 my-2 has-text-left is-flex align-items-center justify-content-center">
              <img
                className="mr-3"
                width={18}
                height={18}
                src={IconError}
                alt="warning sign"
              />
              {error}
            </p>
          ) : null}
          <div className="is-hidden-tablet">
            <SubmitButton
              small
              className={cx("is-fullwidth", buttonClass)}
              onClick={submitForm}
            >
              {buttonText}
            </SubmitButton>
          </div>
          <div className="is-hidden-mobile">
            <SubmitButton
              className={cx("is-fullwidth", buttonClass)}
              onClick={submitForm}
            >
              {buttonText}
            </SubmitButton>
          </div>
        </div>
      </div>
    </Columns>
  );
};

export default NewsletterSignUp;
