import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../Home";
import ForBars from "../ForBars";
import ForBrands from "../ForBrands";
import BarPage from "../BarPage";
import CollectionPage from "../CollectionPage";
import EditPage from "../EditPage";
import Support from "../Support";
import Unsubscribe from "../Unsubscribe";
import PrivacyPolicy from "../PrivacyPolicy";
import PremiumTerms from "../PremiumTerms";
import NotFound from "../NotFound";
import Redirect from "../Redirect";
import TrackCustomer from "../covidTracking/TrackCustomer";
import TrackTracePrivacyPolicy from "../TrackTracePrivacyPolicy";
import ForRewardBrands from "app/ForRewardBrands";
import DuskAccelerator from "../DuskAccelerator";

const Router = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/for-bars" component={ForBars} />
    <Route path="/for-brands" component={ForBrands} />
    <Route path="/for-rewards" component={ForRewardBrands} />
    <Route path="/dusk-accelerator" component={DuskAccelerator} />
    <Route path="/support" component={Support} />
    <Route path="/premiumterms" component={PremiumTerms} />
    <Route path="/group/:slug" component={CollectionPage} />
    <Route path="/bar/:location/:slug" component={BarPage} />
    <Route path="/edit/:location/:slug" component={EditPage} />
    <Route path="/unsubscribe" component={Unsubscribe} />
    <Route path="/unsub" component={Unsubscribe} />
    <Route path="/r/:slug" component={Redirect} />
    <Route path="/tracking/:slug" component={TrackCustomer} />
    <Route
      path={["/privacy-policy", "/privacy_policy"]}
      component={PrivacyPolicy}
    />
    <Route
      path="/track-trace-privacy-policy"
      component={TrackTracePrivacyPolicy}
    />
    <Route component={NotFound} />
  </Switch>
);

export default Router;
