import React, { useMemo } from "react";
import Select, { components } from "react-select";
import cities from "assets/data/subscription-cities.json";
import IconCity from "assets/images/inputIconCity.svg";
import "./CitySelect.scss";

const CitySelect = ({ selectedCity, onChange }) => {
  const cityOptions = useMemo(
    () => cities.map(city => ({ value: city, label: city })),
    []
  );
  const currentCity = useMemo(() => {
    return cityOptions.find(option => option.value === selectedCity) || null;
  }, [cityOptions, selectedCity]);

  return (
    <Select
      isSearchable={true}
      onChange={({ value }) => onChange(value)}
      options={cityOptions}
      value={currentCity}
      className="mb-3"
      classNames={{
        control: () => "city-select-control",
        singleValue: () => "city-select-placeholder",
        placeholder: () => "city-select-placeholder",
        input: () => "city-select-input",
        option: state =>
          state.isSelected
            ? "city-select-option-selected"
            : state.isFocused
            ? "city-select-option-focused"
            : "city-select-option",
      }}
      placeholder="City"
      components={{
        Control: ({ children, ...props }) => (
          <components.Control {...props}>
            <img src={IconCity} alt="city" style={{ float: "left" }} />
            {children}
          </components.Control>
        ),
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export default CitySelect;
