import React, { useRef } from "react";

import { Helmet } from "react-helmet";
import HowItWorks from "shared/HowItWorks";
import EngagementSection from "./forRewardBrands/EngagementSection";

import HeroSection from "./forRewardBrands/HeroSection";
import ImpressionSection from "./forRewardBrands/ImpressionSection";
import RequestOurDeck from "./forRewardBrands/RequestOurDeck";
import UnlockSection from "./forRewardBrands/UnlockSection";
import Stats from "./forBars/Stats";
import RewardLogoCarousel from "shared/RewardLogoCarousel";

const ForRewardBrands = () => {
  const featureRef = useRef();
  return (
    <>
      <Helmet>
        <title>For Reward Brands | DUSK</title>
      </Helmet>
      <HeroSection featureRef={featureRef} />
      <HowItWorks />
      <ImpressionSection />
      <EngagementSection />
      <UnlockSection />
      <Stats />
      <RewardLogoCarousel />
      <RequestOurDeck innerRef={featureRef} />
    </>
  );
};

export default ForRewardBrands;
