import React from "react";
import { TabletOnwards, UpToTablet } from "shared/MediaQuery";
import "./FeatureSection.scss";

/* prettier-ignore */
const features = [
  { icon: require('assets/images/iconMug.png'), title: 'INCREASE SPEND PER HEAD', description: 'We increase the avg. transaction spend by rewarding our users the more they spend with you.' },
  { icon: require('assets/images/iconStar.png'), title: 'DUSK REWARDS', description: 'Win loyalty and custom with our reward platform, where rewards won’t cost you anything.' },
  { icon: require('assets/images/iconTick.png'), title: 'EASY IMPLEMENTATION', description: 'We take care of everything from content to reporting.' },
  { icon: require('assets/images/iconFootPrint.png'), title: 'TRACKABLE FOOTFALL', description: 'Drive significant trackable footfall to your venue at days and times that suit you.' },
  { icon: require('assets/images/iconGPS.png'), title: 'GPS', description: 'We’re built for mobile and use hyper-local marketing to show users the nearest venues to them.' },
]

const Feature = ({ icon, title, description }) => (
  <div className="columns is-mobile" style={{ marginBottom: "24px" }}>
    <div className="column is-2 is-flex is-align-items-flex-start is-justify-content-center">
      <img
        className="feature-icon"
        style={{ objectFit: "contain" }}
        src={icon}
        alt={title}
      />
    </div>
    <div className="column">
      <TabletOnwards>
        <p
          className="dusk-condensed-p-30"
          style={{ marginBottom: "12px", lineHeight: 1.33 }}
        >
          {title}
        </p>
      </TabletOnwards>
      <UpToTablet>
        <p className="dusk-condensed-p-20" style={{ marginBottom: "12px" }}>
          {title}
        </p>
      </UpToTablet>
      <p className="dusk-light-p-16" style={{ maxWidth: 350 }}>
        {description}
      </p>
    </div>
  </div>
);

const FeatureSection = () => {
  return (
    <div className="section dusk-bg-dark">
      <div className="container">
        <div className="columns feature-container">
          <div className="column feature-video-container">
            <video loop muted autoPlay playsInline>
              <source
                src={require("assets/videos/videoBar.mp4")}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="column" style={{ padding: "2rem" }}>
            <h1
              className="dusk-h1-100 has-text-centered-mobile"
              style={{ marginBottom: "2rem" }}
            >
              KEY FEATURES
            </h1>
            {features.map((each) => (
              <Feature key={each.title} {...each} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
