import React, { PureComponent } from "react";
import cx from "classnames";
import ImageInputBlock from "./ImageInputBlock";
import { InputField } from "./Input";
import SubmitButton from "./SubmitButton";
import { validatePhoneNumber, apiCallSendMeApp } from "./utils";
import Image from "./Image";

class GetTheApp extends PureComponent {
  state = {
    number: "",
    error: null,
    sending: false,
    sent: false,
  };

  _numberChanged = (evt) => {
    this.setState({
      number: evt.target.value,
    });
  };

  _sendForm = (number) => {
    apiCallSendMeApp(number).then(
      () => {
        this.setState({
          number: "",
          sending: false,
          sent: true,
        });
      },
      () => {
        this.setState({
          sending: false,
          sent: false,
          error: "An unknown error has occurred. Please try again later.",
        });
      }
    );
  };

  _sendClicked = () => {
    const { number, sending, sent } = this.state;

    if (sending || sent) return;

    if (!number || !validatePhoneNumber(number)) {
      this.setState({ error: "Please enter a valid phone number." });
      return;
    }

    this.setState({
      sending: true,
      error: null,
    });

    this._sendForm(number);
  };

  render() {
    const { number, error, sending, sent } = this.state;

    var className = "is-fullwidth";
    if (sending) {
      className += " is-loading";
    } else if (sent) {
      className += " is-success";
    }

    var buttonText = "Send me the app";
    if (sending) {
      buttonText = "";
    } else if (sent) {
      buttonText = "Sent";
    }

    return (
      <div id="get-the-app">
        <ImageInputBlock
          images={[
            require("../assets/images/downloadPhone.png"),
            require("../assets/images/downloadPhone@2x.png"),
            require("../assets/images/downloadPhone@3x.png"),
          ]}
          align="left"
          alt="Get The App"
          className={this.props.className}
          title="GET THE APP"
          body={{
            default:
              "Give us your number and we'll text you a link to download DUSK on the App Store and Play Store.",
            mobile: "Find the top bars in your city.",
          }}
          mobileReversal={this.props.mobileReversal}
        >
          <div className="is-hidden-mobile">
            <InputField
              className="dusk-m-bot"
              value={number}
              inputType="phone"
              onChange={this._numberChanged}
            />
            {error ? (
              <p className="dusk-p-error-14 my-2 has-text-left is-flex align-items-center justify-content-center">
                <img
                  className="mr-3"
                  width={18}
                  height={18}
                  src={require("assets/images/inputIconError.svg")}
                  alt="warning sign"
                />
                {error}
              </p>
            ) : null}
            <SubmitButton
              className={cx("is-fullwidth", className)}
              onClick={this._sendClicked}
            >
              {buttonText}
            </SubmitButton>
          </div>
          <div className="is-hidden-tablet">
            <a
              className="mobile-store-link"
              href="https://apps.apple.com/gb/app/dusk/id824241550"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                srcs={[
                  require("../assets/images/iconAppStoreWhite.png"),
                  require("../assets/images/iconAppStoreWhite@2x.png"),
                  require("../assets/images/iconAppStoreWhite@3x.png"),
                ]}
                alt="Download on the App Store"
                width={171}
                height={60}
              />
            </a>
            <a
              className="mobile-store-link android-btn"
              href="https://play.google.com/store/apps/details?id=com.drinki.app&hl=en_GB"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                srcs={[
                  require("../assets/images/iconPlayStoreWhite.png"),
                  require("../assets/images/iconPlayStoreWhite@2x.png"),
                  require("../assets/images/iconPlayStoreWhite@3x.png"),
                ]}
                alt="Download on the Play Store"
                width={204}
                height={60}
              />
            </a>
          </div>
        </ImageInputBlock>
      </div>
    );
  }
}

export default GetTheApp;
