import React from "react";
import cx from "classnames";
import { TitleText } from "./typography/Typography";

const Columns = ({
  children,
  className,
  innerClassName,
  isCentered,
  isMultiline,
  title,
  columnProps,
  ...props
}) => (
  <section className={cx("section", innerClassName)} {...props}>
    <div className="container">
      {title ? (
        <TitleText align="centered" className="dusk-m-bot">
          {title}
        </TitleText>
      ) : null}
      <div
        className={
          "columns" +
          (isCentered ? " is-vcentered" : "") +
          (isMultiline ? " is-multiline" : "") +
          (className ? ` ${className}` : "")
        }
        {...columnProps}
      >
        {children}
      </div>
    </div>
  </section>
);

export default Columns;
