import React from "react";
import Ticker from "react-ticker";

/* prettier-ignore */
const Brands = [
  { title: "Bacardi", src: require("assets/images/brandLogoBacardiBlack.png") },
  { title: "Red Bull", src: require("assets/images/brandLogoRedbull.png") },
  { title: "Johnnie Walker", src: require("assets/images/brandLogoJohnnieBlack.png") },
  { title: "Martini", src: require("assets/images/brandLogoMartiniBlack.png") },
  { title: "St. Germain", src: require("assets/images/brandLogoStGermainBlack.png") },
  { title: "Hop House", src: require("assets/images/brandLogoHopHouseBlack.png") },
  { title: "Corona Extra", src: require("assets/images/brandLogoCoronaBlack.png") },
  { title: "Bombay Sapphire", src: require("assets/images/brandLogoBombayBlack.png") },
  { title: "Drambuie", src: require("assets/images/brandLogoDrambuieBlack.png") },
  { title: "Brew Dog", src: require("assets/images/brandLogoBrewdogBlack.png") },
  { title: "Fever Tree", src: require("assets/images/brandLogoFeverTreeBlack.png") },
  { title: "Guinness", src: require("assets/images/brandLogoGuinnessBlack.png") },
];

const Carousel = () => {
  return (
    <Ticker speed={10}>
      {({ index }) => (
        <div
          key={index}
          className="is-flex is-align-items-center is-justify-content-center px-3"
        >
          <img
            style={{
              width: 100,
              height: 100,
              objectFit: "contain",
              objectPosition: "center",
            }}
            src={Brands[index % Brands.length].src}
            alt={Brands[index % Brands.length].title}
          />
        </div>
      )}
    </Ticker>
  );
};

const BrandLogoCarousel = () => {
  return (
    <div className="py-3 bg-black has-text-centered">
      <Carousel />
    </div>
  );
};

export default BrandLogoCarousel;
