import React from "react";
import Layout from "./app/Layout";

import "./App.scss";

const App = () => {
  return <Layout />;
};

export default App;
