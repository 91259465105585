import React from "react";

import IconStar from "assets/images/inputIconStar.svg";
import IconSmile from "assets/images/inputIconSmile.svg";
import IconEmail from "assets/images/inputIconEmail.svg";
import IconPhone from "assets/images/inputIconPhone.svg";
import IconEmailDark from "assets/images/inputIconEmailDark.svg";
import IconSmileDark from "assets/images/inputIconSmileDark.svg";

import "./Input.scss";

const inputTypes = {
  email: {
    placeholder: "Email",
    // icon: "fa-envelope",
    icon: IconEmail,
    iconDark: IconEmailDark,
    type: "email",
  },
  phone: {
    placeholder: "+44",
    // icon: "fa-phone",
    icon: IconPhone,
    type: "tel",
  },
  barName: {
    placeholder: "Bar Name",
    // icon: "fa-building",
    icon: IconStar,
  },
  company: {
    placeholder: "Brand Name",
    // icon: "fa-building",
    icon: IconStar,
  },
  name: {
    placeholder: "Your Name",
    // icon: "fa-address-card",
    icon: IconSmile,
  },
  firstName: {
    placeholder: "First Name",
    icon: IconSmileDark,
    iconDark: IconSmileDark,
  },
};

const InputField = ({ className, inputType, dark = false, ...props }) => (
  <div
    className={`field dusk-field ${dark && "dusk-field-dark"} ${
      className ? className : ""
    }`}
  >
    <p className="control has-icons-left">
      <input
        className="input"
        type={inputTypes[inputType].type ? inputTypes[inputType].type : "text"}
        placeholder={inputTypes[inputType].placeholder}
        {...props}
      />
      <span className="icon is-left">
        <img
          src={
            dark && inputTypes[inputType].iconDark
              ? inputTypes[inputType].iconDark
              : inputTypes[inputType].icon
          }
          alt={`${inputTypes[inputType].type}-icon`}
          className="input-icon"
        />
        {/* <i className={"fas " + inputTypes[inputType].icon} /> */}
      </span>
    </p>
  </div>
);

const TextArea = ({
  children,
  className,
  placeholder,
  dark = false,
  ...props
}) => (
  <div
    className={`field dusk-field ${dark ? "dusk-field-dark" : ""} ${
      className ? className : ""
    }`}
  >
    <div className="control">
      <textarea className="textarea" placeholder={placeholder} {...props}>
        {children}
      </textarea>
    </div>
  </div>
);

export { InputField, TextArea };
