import React from "react";

import FeaturedIn from "shared/FeaturedIn";
import HowItWorks from "shared/HowItWorks";
import HeroSection from "./home/HeroSection";
import LinkSection from "./home/LinkSection";
import DrinkSection from "./home/DrinkSection";
import RewardSection from "./home/RewardSection";
import DownloadNow from "./home/DownloadNow";
import WorkWithUs from "./home/WorkWithUs";
import UserTestimonialSection from "./home/UserTestimonialSection";
import LocationCarouselSection from "./home/LocationCarouselSection";
import NewsletterSignUp from "./home/NewsletterSignUp";
import "./Home.scss";

const Home = () => {
  return (
    <>
      <HeroSection />
      <HowItWorks />
      <DrinkSection />
      <LinkSection />
      <RewardSection />
      <UserTestimonialSection />
      <DownloadNow />
      <LocationCarouselSection />
      <WorkWithUs />
      <FeaturedIn />
      <NewsletterSignUp />
    </>
  );
};

export default Home;
