import React, { useCallback } from "react";
import NewHero from "shared/NewHero";
import { HeroButton } from "shared/Button";
import "./HeroSection.scss";

const HeroSection = ({ featureRef }) => {
  const goToFeature = useCallback(() => {
    featureRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [featureRef]);
  return (
    <NewHero size="large">
      <div className="columns">
        <div className="column is-5-desktop is-6">
          <h1 className="dusk-h1-outline-100 dusk-m-bot">
            ON-TRADE <span className="has-text-white">ACTIVATION</span>
          </h1>
          <p className="dusk-p-18 mb-3">
            Brands sponsor free drinks in DUSK bars, getting their perfect serve
            into millennial and Gen-Z hands in the on-trade.
          </p>
          <div className="has-text-centered-mobile">
            <HeroButton onClick={goToFeature}>Request our deck</HeroButton>
          </div>
        </div>
        <div className="column is-offset-3-desktop is-4-desktop is-6 is-flex image-control">
          <video loop muted autoPlay playsInline>
            <source
              src={require("assets/videos/videoBrand.mp4")}
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </NewHero>
  );
};

export default HeroSection;
