import React, { Fragment } from "react";
import Hero from "../../shared/Hero";
import { HeroText, ParagraphText } from "../../shared/typography/Typography";
import GetTheApp from "../../shared/GetTheApp";

const CheckedIn = ({ barInfo: { name } }) => (
  <Fragment>
    <Hero>
      <HeroText align="centered">You've checked into {name}</HeroText>
      <ParagraphText className="mt20" align="centered">
        If you test positive for COVID-19 please inform NHS Test &amp; Trace
        that you visited this bar and checked in with DUSK app
      </ParagraphText>
    </Hero>
    <div className="section dusk-negate-t-padding" id="tracking-confirm">
      <div className="container has-text-centered">
        <video className="confirm-tick" autoPlay playsInline>
          <source
            src={require("../../assets/videos/confirmTick.mp4")}
            type="video/mp4"
          />
        </video>
        <ParagraphText align="centered">
          Now don't forget to always wash your hands{" "}
          <span role="img" aria-label="soap">
            🧼
          </span>
        </ParagraphText>
      </div>
    </div>
    <GetTheApp />
  </Fragment>
);

export default CheckedIn;
