import React, { Fragment } from "react";
import Hero from "../shared/Hero";
import { HeroText } from "../shared/typography/Typography";

import { Helmet } from "react-helmet";
import UnsubscribeBlock from "./unsubscribe/UnsubscribeBlock";

const Unsubscribe = () => (
  <Fragment>
    <Helmet>
      <title>Unsubscribe | DUSK</title>
    </Helmet>
    <Hero>
      <HeroText className="dusk-m-top-xl has-text-white" align="centered">
        DON'T GO, DON'T LEAVE
      </HeroText>
    </Hero>
    <UnsubscribeBlock />
  </Fragment>
);

export default Unsubscribe;
