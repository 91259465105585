import React from "react";
import PhoneFrameImage from "shared/PhoneFrameImage";
import { DarkSection } from "shared/Section";
import RewardImage from "assets/images/homePageReward.png";
import RewardImageDesktop from "assets/images/homePageRewardDesktop.png";

const RewardSection = () => {
  return (
    <DarkSection>
      <div className="columns section-dusk-columns">
        <div className="column is-5 is-offset-1 section-dusk-text-column has-text-centered-mobile"
        >
          <div style={{ maxWidth: 380 }}>
            <h1 className="dusk-h1-160 has-text-white">EARN.</h1>
            <p className="dusk-medium-p-20-14">
              Use your points to unlock exclusive rewards from top brands like
              Patch Plants and Grind.
            </p>
            <p className="dusk-p-20">TREAT YOURSELF, YOU EARNED IT.</p>
          </div>
        </div>

        <div className="column is-6 section-dusk-image-column">
          <div className="section-dusk-image-container">
            <div className="is-hidden-mobile">
              <PhoneFrameImage
                shadow
                className="image"
                src={RewardImageDesktop}
                alt="app reward screen"
              />
            </div>
            <div className="is-hidden-tablet">
              <PhoneFrameImage
                shadow
                className="image"
                src={RewardImage}
                alt="app reward screen"
              />
            </div>
          </div>
        </div>
      </div>
    </DarkSection>
  );
};

export default RewardSection;
