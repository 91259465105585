import React from "react";
import { TertiaryParagraphText } from "../../shared/typography/Typography";
import "./CollectionDetail.scss";

const CollectionDetail = ({ data }) => {
  const { description } = data;

  return (
    <div className="container">
      <section className="section collection-page">
        <TertiaryParagraphText>{description}</TertiaryParagraphText>
      </section>
    </div>
  );
};

export default CollectionDetail;
