import React, { Fragment, PureComponent } from "react";
import Hero from "../shared/Hero";
import BarDetail from "./barPage/BarDetail";
import MobileBottomButton from "../shared/MobileBottomButton";
import RecommendedBar from "./barPage/RecommendedBar";
import GetTheApp from "../shared/GetTheApp";
import Loading from "../shared/Loading";
import { TitleText } from "../shared/typography/Typography";

import { withRouter } from "react-router-dom";

import { Helmet } from "react-helmet";

import { apiGetBarDetail } from "../shared/utils";

class BarPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      data: null
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { location, slug }
      }
    } = this.props;
    apiGetBarDetail(`${location}/${slug}`)
      .then(result => {
        this.setState({
          data: result.data,
          isLoading: false,
          error: result.status !== 200 ? "We couldn't find that bar..." : null
        });
      })
      .catch(error => {
        if (error.response) {
          this.setState({
            error: error.response.data.fail || "We couldn't find that bar...",
            isLoading: false,
            data: null
          });
        } else {
          this.setState({
            error: "We couldn't find that bar...",
            isLoading: false,
            data: null
          });
        }
      });
  }

  render() {
    const { isLoading, data, error } = this.state;
    if (isLoading) return <Loading />;
    if (error)
      return (
        <Fragment>
          <Helmet>
            <title>404 | DUSK</title>
          </Helmet>
          <Hero background={null} fade bottomAlign>
            <TitleText className="dusk-m-bot-full">404</TitleText>
          </Hero>
          <section className="section">
            <div className="container">{error}</div>
          </section>
        </Fragment>
      );
    const {
      venue: { name, share_url, image_url },
      related
    } = data;
    return (
      <Fragment>
        <Helmet>
          <title>{name || "Bar"} | DUSK</title>
        </Helmet>

        <Hero background={image_url} fullHeight fade bottomAlign>
          <TitleText className="dusk-m-bot-full">{name}</TitleText>
        </Hero>
        <Fragment>
          <MobileBottomButton to={share_url || "/"} />
          <BarDetail data={data.venue} />
          {related.length ? <RecommendedBar data={data.related} /> : null}
        </Fragment>
        <GetTheApp mobileReversal={true} />
      </Fragment>
    );
  }
}

export default withRouter(BarPage);
