import React from "react";
import { TagText } from "./typography/Typography";
import "./Tag.scss";

const Tag = ({ children, className, ...props }) => (
  <span
    className={"tag is-primary" + (className ? ` ${className}` : "")}
    {...props}
  >
    <TagText>{children}</TagText>
  </span>
);

export default Tag;
