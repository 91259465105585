import React from "react";
import cx from "classnames";
import "./SectionWithImage.scss";

const SectionWithImage = ({ className, src, children, ...props }) => (
  <div className={cx("section dusk-section-image", className)} {...props}>
    {children}
  </div>
);

export default SectionWithImage;
