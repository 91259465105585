import React from "react";
import { Link } from "react-router-dom";
import SectionWithImage from "shared/SectionWithImage";
import { RoundedButton } from "shared/Button";
import "./WorkWithUs.scss";

const WorkWithUs = () => {
  return (
    <SectionWithImage
      className="is-flex is-align-items-center is-flex-direction-column is-justify-content-center"
      id="work"
    >
      <h1 className="dusk-h1-110 h1-text-shadow mb-md-6">WORK WITH US</h1>
      <div className="columns work-with-us-buttons">
        <div className="column">
          <Link to="/for-bars">
            <RoundedButton size="large">Venues</RoundedButton>
          </Link>
        </div>
        <div className="column">
          <Link to="/for-brands">
            <RoundedButton size="large">Drinks Brands</RoundedButton>
          </Link>
        </div>
        <div className="column">
          <Link to="/for-rewards">
            <RoundedButton size="large">Rewards Partners</RoundedButton>
          </Link>
        </div>
      </div>
      {/* <div className="has-text-centered">
        <p className="dusk-p-20 mb-3 px-sm-2">
          LAUNCHING AN ON-TRADE ACTIVATION?
          <br />
          <br />
          LOOKING TO GET LISTED ON DUSK? WANT
          <br />
          <br />
          TO JOIN OUR REWARD SCHEME?
        </p>
        <div className="is-hidden-mobile">
          <Button>Find out more</Button>
        </div>
        <div className="is-hidden-tablet">
          <Button small>Find out more</Button>
        </div>
      </div> */}
    </SectionWithImage>
  );
};

export default WorkWithUs;
