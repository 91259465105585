import React from "react";
import Image from "./Image";
import { TertiaryParagraphText } from "./typography/Typography";
import "./IconDescription.scss";

const icons = {
  tube: {
    images: [
      require("../assets/images/iconTube.png"),
      require("../assets/images/iconTube@2x.png"),
      require("../assets/images/iconTube@3x.png")
    ],
    title: "tube"
  },
  drink: {
    images: [
      require("../assets/images/iconCocktail.png"),
      require("../assets/images/iconCocktail@2x.png"),
      require("../assets/images/iconCocktail@3x.png")
    ],
    title: "drink"
  },
  offer: {
    images: [
      require("../assets/images/iconOffer.png"),
      require("../assets/images/iconOffer@2x.png"),
      require("../assets/images/iconOffer@3x.png")
    ],
    title: "offer"
  }
};

const IconDescription = ({ className, type, body }) => {
  const icon = icons[type];
  return (
    <div
      className={`${
        className ? `${className} ` : ""
      }icon-description-container`}
    >
      <div className="icon-description-image-container">
        <Image
          srcs={icon.images}
          alt={icon.title}
          width={"46px"}
          height={"46px"}
          className="icon-description-image"
        />
      </div>
      <TertiaryParagraphText className="icon-description-text is-inline-block">
        {body}
      </TertiaryParagraphText>
    </div>
  );
};

export default IconDescription;
