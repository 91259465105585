import React from "react";
import { TabletOnwards, UpToTablet } from "shared/MediaQuery";
import PhoneFrameImage from "shared/PhoneFrameImage";
import "./Packages.scss";

/* prettier-ignore */
const featuresOne = [
  { icon: require('assets/images/iconMug.png'), title: 'SAMPLING / LIQUID ON LIPS', description: '✅ Up to 650 ‘Perfect Serve’ samples into the hands of Millennials and Gen-Zers.\n✅ Driving increased footfall and spend to 5 or more existing customers.\n✅ A/B serve / copy testing.' },
  { icon: require('assets/images/iconStar.png'), title: 'MARKETING & EVENTS', description: '✅ 2 million brand impressions across DUSK owned media.\n✅ Cohort press release promoted through DUSK PR.\n✅ Exclusive DUSK Influencer Event.' },
  { icon: require('assets/images/iconChart.png'), title: 'DATA DRIVEN', description: '✅ Audience insight reporting.\n✅ Weekly sampling updates.\n✅ Recommendations based on audience feedback.' },
]
/* prettier-ignore */
const featuresTwo = [
  { icon: require('assets/images/iconMug.png'), title: 'SAMPLING / LIQUID ON LIPS', description: '✅ Up to 500 ‘Perfect Serve’ samples into the hands of Millennials and Gen-Zers.\n✅ Brand in hand without a listing.\n✅ A/B serve / copy testing.' },
  { icon: require('assets/images/iconStar.png'), title: 'MARKETING & EVENTS', description: '✅ Supporting your earliest customer(s) by driving footfall and spend.\n✅ Social media (reel or TikTok) + posts.\n✅ Newsletter inclusion (300,000 subscribers)' },
  { icon: require('assets/images/iconChart.png'), title: 'DATA DRIVEN', description: '✅ Audience insight reporting.\n✅ Weekly sampling updates.\n✅ Recommendations based on audience feedback.' },
]

const Feature = ({ icon, title, mobileTitle, description }) => (
  <div className="columns is-mobile" id="how-to-use-feature">
    <div className="column is-2 is-flex is-align-items-flex-start-tablet is-align-items-center-mobile is-justify-content-center">
      <img
        className="use-icon"
        style={{ objectFit: "contain" }}
        src={icon}
        alt={title}
      />
    </div>
    <div className="column is-flex is-justify-content-center is-flex-direction-column">
      <TabletOnwards>
        <p
          className="dusk-condensed-p-30"
          style={{ marginBottom: "12px", lineHeight: 1.33 }}
        >
          {title}
        </p>
      </TabletOnwards>
      <UpToTablet>
        <p className="dusk-condensed-p-30">{mobileTitle || title}</p>
      </UpToTablet>
      <p className="dusk-light-p-16 split-description">{description}</p>
    </div>
  </div>
);

const Packages = () => {
  return (
    <div className="section use-section dusk-bg-dark">
      <div className="container">
        <div className="columns use-container">
          <div className="column use-image-container is-hidden-mobile">
            <div className="image">
              <PhoneFrameImage
                shadow
                className="image-override"
                src={require("assets/images/accelerator-drinkdetail.png")}
              />
            </div>
          </div>
          <div className="column use-text-container" id="right">
            <h1 className="dusk-h1-80-64 has-text-centered-mobile" style={{ marginBottom: "0.5rem" }}>
              FRESH
            </h1>
            <p className="dusk-p-18 mb-3" style={{ marginBottom: "2rem" }}>
              For brands with a boutique mindset. Stocked in at least 5 venues, we’ll bring our users to you - increasing brand in hand daily. 
              <br /><br />
              New drinks brands (NDBs) accepted into the program will supported by a 1 month DUSK campaign, delivering:
              <br/><br/>
            </p>
            {featuresOne.map((each) => (
              <Feature key={each.title} {...each} />
            ))}
          </div>
        </div>
        <div className="is-hidden-tablet use-image-standalone-container">
          <PhoneFrameImage
            shadow
            className="use-image-standalone"
            src={require("assets/images/accelerator-drinkdetail.png")}
          />
        </div>
        <div className="columns use-container">
          <div className="column use-text-container" id="left">
            <h1 className="dusk-h1-80-64 has-text-centered-mobile" style={{ marginBottom: "0.5rem" }}>
              SUPER-FRESH
            </h1>
            <p className="dusk-p-18 mb-3" style={{ marginBottom: "2rem" }}>
            For those just getting started. Stocked in at least 1 venue, we will introduce your brand to new, eager customers. 
            <br/><br/>
            </p>
            {featuresTwo.map((each) => (
              <Feature key={each.title} {...each} />
            ))}
          </div>
          <div className="column use-image-container is-hidden-mobile">
            <div className="image" id="right">
              <PhoneFrameImage
                shadow
                className="image-override"
                src={require("assets/images/accelerator-mapview.png")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
