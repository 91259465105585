import axios from "axios";
import constants from "../constants";

// Mock Data
// import MockDataDrink from "../test-data/bar-mock-drinks.json";
// import MockDataNoDrink from "../test-data/bar-mock-no-drinks.json";
// import MockDataGroup from "../test-data/collection-mock.json";
// import MockDataEdit from "../test-data/edit-mock.json";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^(\+?[1-9])?\d{6,14}$/;

const truncateString = string => {
  // truncate if character is longer than 256 characters
  if (string.length <= 256) return string;
  var re = string.match(/^.{0,256}[\S]*/);
  const l = re[0].length;
  re = re[0].replace(/\s$/, "");
  if (l < string.length) re = re + "...";
  return re;
};

const validateEmail = email => emailRegex.test(email);
const validatePhoneNumber = number => phoneRegex.test(number);

const apiCallContact = payload =>
  axios.post("https://web-api.dusk.app/websiteContactUsAPI", payload);

const apiCallSendMeApp = number =>
  axios.post("https://web-api.dusk.app/websiteTextMeAPI", { phone: number });

const apiCallUnsubscribe = email =>
  axios.post(`${constants.appApiEndpoint}/marketing/unsubscribe`, {
    email,
  });

const apiCallNewsletterSignup = payload =>
  axios.post(`${constants.appApiEndpoint}/marketing/subscribe`, payload);

const apiGetBarDetail = slug =>
  axios.get(`https://api.dusk.app/api/web/venue`, { params: { slug } });

const apiGetCollection = slug =>
  axios.get(`https://api.dusk.app/api/web/group`, { params: { slug } });

const apiGetEdit = slug =>
  axios.get(`https://api.dusk.app/api/web/edit`, { params: { slug } });

const apiGetRedirect = slug =>
  axios.get("https://api.dusk.app/api/web/redirect", {
    params: { slug },
  });

const apiGetTrackingBarInfo = slug =>
  axios.get("https://web-api.dusk.app/get-bar-info", { params: { slug } });

const apiTrackCustomerData = payload =>
  axios.post("https://web-api.dusk.app/customer-check-in", payload);

export {
  apiCallContact,
  apiCallSendMeApp,
  apiCallUnsubscribe,
  apiCallNewsletterSignup,
  validateEmail,
  validatePhoneNumber,
  apiGetBarDetail,
  apiGetCollection,
  apiGetEdit,
  apiGetRedirect,
  truncateString,
  apiGetTrackingBarInfo,
  apiTrackCustomerData,
};
