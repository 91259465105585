import React from "react";
import cx from "classnames";
import "./Section.scss";

const Section = ({ children, className, bg, ...props }) => {
  return (
    <div
      className={cx(
        "section section-dusk",
        bg ? `dusk-bg-${bg}` : undefined,
        className
      )}
      {...props}
    >
      <div className="container section-dusk-body">{children}</div>
    </div>
  );
};

const DarkSection = (props) => <Section bg="dark" {...props} />;

const LightSection = (props) => <Section bg="light" {...props} />;

export { DarkSection, LightSection };
