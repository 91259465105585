import React from "react";
import SectionWithImage from "shared/SectionWithImage";
import Image from "shared/Image";

const DownloadNow = () => {
  return (
    <SectionWithImage
      id="download"
      className="is-flex is-align-items-center is-flex-direction-column is-justify-content-center"
    >
      <h1 className="dusk-h1-110 h1-text-shadow">DOWNLOAD NOW</h1>
      <div className="has-text-centered">
        <a
          className="mr-md-2"
          href="https://apps.apple.com/gb/app/dusk/id824241550"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            srcs={[
              require("../../assets/images/iconAppStore.png"),
              require("../../assets/images/iconAppStore@2x.png"),
              require("../../assets/images/iconAppStore@3x.png"),
            ]}
            alt="Download on the App Store"
            width={200}
            // height={37}
          />
        </a>
        <a
          // className="is-block"
          href="https://play.google.com/store/apps/details?id=com.drinki.app&hl=en_GB"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            srcs={[
              require("../../assets/images/iconGooglePlay.png"),
              require("../../assets/images/iconGooglePlay@2x.png"),
              require("../../assets/images/iconGooglePlay@3x.png"),
            ]}
            alt="Download on the Play Store"
            width={200}
            // width={120}
            // height={37}
          />
        </a>
      </div>
    </SectionWithImage>
  );
};

export default DownloadNow;
