import React from "react";
import { NavLink } from "react-router-dom";
import { TabletOnwards, UpToTablet } from "shared/MediaQuery";
import { ButtonText, LinkText, DropdownLinkText } from "./Typography";

const Link = ({ external, children, footer, to }) => {
  const child = footer ? (
    <LinkText>{children}</LinkText>
  ) : (
    <ButtonText>{children}</ButtonText>
  );

  if (to.startsWith("https") || external) {
    return (
      <a className="link" href={to}>
        {child}
      </a>
    );
  }

  return (
    <NavLink className="link" activeClassName="is-active" to={to}>
      {child}
    </NavLink>
  );
};

const HeaderLink = ({ children, to }) => {
  return (
    <NavLink className="link" activeClassName="is-active" to={to}>
      <LinkText>{children}</LinkText>
    </NavLink>
  );
};

const HeaderDropdownLink = ({ children, to }) => {
  return (
    <NavLink className="link" activeClassName="is-active" to={to}>
      <UpToTablet>
        <DropdownLinkText className="has-text-centered level-item dusk-condensed-p-16">
          {children}
        </DropdownLinkText>
      </UpToTablet>
      <TabletOnwards>
        <DropdownLinkText className="has-text-centered level-item dusk-condensed-p-20">
          {children}
        </DropdownLinkText>
      </TabletOnwards>
    </NavLink>
  );
};

export { Link, HeaderLink, HeaderDropdownLink };
