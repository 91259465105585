import React, { Fragment } from "react";
import Hero from "../shared/Hero";
import { HeroText } from "../shared/typography/Typography";

import { Helmet } from "react-helmet";
import PremiumTermsBlock from "./premiumTerms/PremiumTermsBlock";

const PremiumTerms = () => (
  <Fragment>
    <Helmet>
      <title>Terms and Conditions for Premium Profiles (UK) | DUSK</title>
    </Helmet>
    <Hero>
      <HeroText className="dusk-m-top-xl" align="centered">
        Terms and Conditions for Premium Profiles (UK)
      </HeroText>
    </Hero>
    <PremiumTermsBlock />
  </Fragment>
);

export default PremiumTerms;
