import React from "react";
import { NavLink } from "react-router-dom";
import {
  TitleText,
  SecondaryParagraphText,
  ParagraphText
} from "../../shared/typography/Typography";

import "./PremiumTermsBlock.scss";

const PremiumTermsBlock = () => (
  <div className="section premium-terms">
    <div className="container">
      <TitleText align="centered" className="dusk-m-bot">
        DUSK® (Drinki Limited)
      </TitleText>
      <ParagraphText>1. Your Premium Profile Agreement</ParagraphText>
      <SecondaryParagraphText>
        Please read these Terms and Conditions carefully before entering into
        the Premium Profile Agreement. Upon Acceptance of your order, you are
        agreeing to be bound by these Terms and Conditions.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        DUSK reserves the right to amend these Terms and Conditions at any time.
        By posting the amended Terms and Conditions on the DUSK Website, DUSK
        shall be deemed to have given the Client notice of any such changes.
      </SecondaryParagraphText>
      <ParagraphText>2. Definition and Interpretation</ParagraphText>
      <SecondaryParagraphText>
        “Business Day” means a day other than a Saturday, Sunday or public
        holiday in England when banks in London are open for business;
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        “Client Materials” means any copy, artwork, photographs, video clips
        and/or other material provided by the Client for inclusion in a Listing
        or otherwise; “Fees” means the charges to be paid by the Client as set
        out in the Purchase Order and Annex Page or as advised from time to
        time;
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        “Listing” means the information published on the DUSK Website relating
        to the venue or event for which the Client is contracting Services;
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        “Mobile App” means the mobile application by means of which Listing can
        be accessed;
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        “DUSK” means Drinki Limited, full details of which can be found in
        Clause 20 of these Terms and Conditions;
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        “DUSK Website” and “Website” means the website located at{" "}
        <NavLink to="/">www.dusk.app</NavLink> and includes the Mobile App; and
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        “Services” means the services paid for by the Client, as specified in
        Clause 3 of these Terms and Conditions.
      </SecondaryParagraphText>
      <ParagraphText>3. Services</ParagraphText>
      <SecondaryParagraphText>
        3.1. In consideration of the payment of the Fees by the Client in
        accordance with Clause 6 of these Terms and Conditions, DUSK will
        provide the Client with the following Services in relation to the
        Listing:
      </SecondaryParagraphText>
      <ol type="a">
        <li>
          <SecondaryParagraphText>
            Access to its unique, secure dashboard in accordance with the level
            of Services purchased, as set out in the Purchase Order and detailed
            in Schedule 1.
          </SecondaryParagraphText>
        </li>
      </ol>
      <SecondaryParagraphText>
        3.2 DUSK will, subject to Clause 9.1, use reasonable efforts to provide
        the Services which the Client has purchased from DUSK on a continuous
        basis.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        3.3 Following the Client entering into the Premium Profile Agreement,
        the Listing will be published on the DUSK Website within two (2)
        Business Days.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        3.4 DUSK will use all reasonable endeavours to remedy faults or accuracy
        errors on the DUSK Website. The Client acknowledges and accepts that
        once a fault or accuracy error has been reported to DUSK it may take up
        to two (2) Business Days for DUSK to investigate and remedy the problem.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        3.5 DUSK shall be entitled to alter features of the Services from time
        to time, upon notification to the Client.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        3.6 The Client accepts, understands and agrees that in the event that it
        is the opinion of DUSK that any Client Materials infringes any third
        party rights, breaches any of the warranties set out in Clause 5 below,
        or is offensive, inaccurate or defamatory, DUSK will have the right to
        remove all or part of such Client Materials without notice and without
        compensation.
      </SecondaryParagraphText>
      <ParagraphText>4. Client Materials</ParagraphText>
      <SecondaryParagraphText>
        4.1 The Client shall be solely responsible for checking the accuracy of
        any Listing for errors.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        4.2 DUSK may receive Client Materials via such means as specified by
        DUSK from time to time. The Client accepts that it submits Client
        Materials at its own risk and DUSK shall not be liable or responsible
        for non-receipt or corruption of any text, font or image sent by the
        Client. The Client may be required to provide imagery for its Listing.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        4.3 DUSK reserves the right to refuse to use any Client supplied Client
        Materials, and/or replace these with Client Materials selected by DUSK.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        4.4 The Client grants DUSK a non-exclusive, non-transferable,
        sub-licensable, royalty-free, worldwide, perpetual licence to use the
        Client Materials. This licence may include granting permission to third
        parties to use any or all of the Client Materials.
      </SecondaryParagraphText>
      <ParagraphText>5. Clients Obligations and Warranties</ParagraphText>
      <SecondaryParagraphText>
        5.1 The Client warrants that:
      </SecondaryParagraphText>
      <ol type="a">
        <li>
          <SecondaryParagraphText>
            it has the right to act on behalf of the business being advertised
            and the information being provided does not infringe any patent,
            trademark, copyright or any other proprietary rights of any third
            party;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            all Client Materials and any other information and materials
            provided by the Client are complete, accurate, legal,
            non-defamatory, decent, honest and truthful and comply with the
            British Code of Advertising Practice, all other relevant codes under
            the general supervision of the Advertising Standards Authority and
            all current relevant legislation;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            the Client will comply with all current legislation, advertising
            codes and any other regulatory requirements, including without
            limitation the Consumer Protection from Unfair Trading Regulations
            2008 and The UK Code of Non-Broadcast Advertising, Sales Promotion
            and Direct Marketing; and
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            The Client will only supply Client Materials which comply in all
            respects with these Terms and Conditions, at all times.
          </SecondaryParagraphText>
        </li>
      </ol>

      <ParagraphText>6. Payment</ParagraphText>

      <SecondaryParagraphText>
        6.1 The Client shall pay to DUSK the Fees for all Services specified in
        the Purchase Order Payment and Annex Page shall be made by credit or
        debit card, the details of which are set out in the Purchase Order, on
        entering into the Premium Profile Agreement and shall ensure that DUSK
        has valid extant credit card details at all times.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        6.2 The Client hereby authorises DUSK to take the agreed monthly Fees
        together with VAT using the credit card in advance of the provision of
        Services on the commencement of the Premium Profile Agreement and on the
        same day of each month during the Term. All Fees are exclusive of VAT
        which will be charged in addition and payable by the Client.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        6.3 If for any reason payment is not received, DUSK reserves the right
        to suspend or remove all Services until such time as payment has been
        received in full. If the Client’s payment method fails or the account is
        past due, DUSK may collect past due amounts using other collection
        mechanisms, and the Client agrees to pay all expenses associated with
        such collection, including interest at judgement rate and its legal
        fees. The Client agrees to accept electronic VAT invoices on a monthly
        basis from DUSK. The Client represents that it is authorised to incur
        the Fees against the credit card provided.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        6.4 If the Client fails to make a payment of the Fees to DUSK by the due
        date for the payment, then the Client shall pay interest on the overdue
        amount at the rate of 4% (four per cent) above the Barclays Bank Plc’s
        base rate from time to time and such interest will accrue daily and will
        be calculated on a day to day basis on all overdue accounts from the
        date on which the payment first became due until the date when payment
        is made in full.
      </SecondaryParagraphText>

      <ParagraphText>7. Cancellation and Termination</ParagraphText>
      <SecondaryParagraphText>
        7.1 The Premium Profile Agreement shall continue in force for the term
        specified on the Purchase Order (the “
        <span className="b">Initial Term</span>”) and will renew for the same
        period on a rolling basis and on the same terms automatically (a "
        <span className="b">Renewed Term</span>”), unless terminated in
        accordance with Clause 7.2, Clause 7.4 or Clause 18 below.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        7.2 The Premium Profile Agreement can be terminated by the Client
        serving DUSK with written notice not less than thirty (30) days prior to
        the end of the Initial Term or a Renewed Term, sent to the address
        specified on the invoice with a copy to{" "}
        <a href="mailto:support@dusk.app">Support@dusk.app</a> (the “
        <span className="b">Notice</span>”).
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        7.3 Should the Client wish to terminate the Premium Profile Agreement
        prior to the expiry of the Initial Term or a Renewed Term, in accordance
        with Clause 7.2, the Client will be obliged to pay an early termination
        fee (the “<span className="b">Early Termination Fee</span>”). The Early
        Termination Fee will be calculated as the amount equal to the
        outstanding Fees owed by the Client up to the end of the Initial Term or
        a Renewed Term.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        7.4 If the Client is in breach of the Premium Profile Agreement, DUSK
        reserves the right to terminate the Premium Profile Agreement with
        immediate effect by giving notice to the Client via email or telephone,
        and reserves the right to bring the provision of the Services to an
        immediate end and seek any applicable damages against the Client.
      </SecondaryParagraphText>

      <ParagraphText>8. Fee Variation</ParagraphText>

      <SecondaryParagraphText>
        DUSK shall have the right to change its Fees at any time and for any
        reason. Any changes will become applicable at the start of the renewal
        period of the Services.
      </SecondaryParagraphText>

      <ParagraphText>9. DUSK Disclaimer of Warranties</ParagraphText>

      <SecondaryParagraphText>
        9.1 DUSK will use reasonable endeavours to keep the DUSK Website up,
        functioning and providing the Services but the Services and the DUSK
        Website are provided “as is”. DUSK makes no warranties, express or
        implied, including but not limited to implied warranties of
        merchantability or fitness for a particular purpose. DUSK does not
        guarantee that the Services or the Website will always be error free or
        will always function without disruptions, delays or imperfections. DUSK
        is not responsible for the actions, content, information or data of
        third parties and you will not look to DUSK, its directors, officers,
        agents or employees to compensate you in connection therewith. The
        Client agrees to hold DUSK harmless against all losses, liabilities,
        claims or demands arising out of any omissions or breaches of any of its
        obligations.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        9.2 Under no circumstances shall DUSK’s total liability in relation to
        any error, misprint or omission exceed either; (i) the amount of any
        Fees paid to DUSK for the Services in connection with which liability
        arose; or (ii) the cost of a further or corrective advertisement of a
        type and standard reasonably comparable to that in connection with which
        the liability arose; whichever is the lower amount.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        9.3 Save as set out above, DUSK accepts no liability in respect of any
        loss or damage occasioned directly or indirectly as a result of
        publication of any advertisement or any loss or damage occasioned
        directly or indirectly by any total or partial failure (however caused)
        of publication to the Website in which any Listing is or was scheduled
        to appear.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        9.4 Nothing in the Premium Profile Agreement excludes DUSK’s liability
        for death or personal injury caused by its own negligence or for any
        other liability which may not be excluded by law.
      </SecondaryParagraphText>

      <ParagraphText>10. Waiver</ParagraphText>

      <SecondaryParagraphText>
        If DUSK delays or fails to enforce any of these Terms and Conditions it
        shall not mean that DUSK has waived its right to do so.
      </SecondaryParagraphText>

      <ParagraphText>11. Assignment</ParagraphText>

      <SecondaryParagraphText>
        DUSK shall be entitled to assign any of DUSK’s rights and obligations
        under these Terms and Conditions provided your rights are not adversely
        affected.
      </SecondaryParagraphText>

      <ParagraphText>12. Severability</ParagraphText>

      <SecondaryParagraphText>
        If it is found by a court that any of these Terms and Conditions for any
        reason cannot be enforced, this shall not prevent the other provisions
        from continuing to apply.
      </SecondaryParagraphText>

      <ParagraphText>13. Third Party Rights</ParagraphText>

      <SecondaryParagraphText>
        Any person who is not a party to this Premium Profile Agreement shall
        have no rights under the Contracts (Rights of Third Parties) Act 1999 to
        enforce any term of its terms.
      </SecondaryParagraphText>

      <ParagraphText>14. Entire Agreement</ParagraphText>

      <SecondaryParagraphText>
        14.1 These Terms and Conditions and any document expressly referred to,
        and any specially agreed terms constitute the entire agreement between
        the parties in connection to its subject matter and supersede any
        previous terms and conditions, agreement or arrangement between the
        parties.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        14.2 Each of the parties agrees that it has not entered into these Terms
        and Conditions in reliance on, and shall have no remedy in respect of,
        any statement, representation, covenant, warranty, undertaking or
        indemnity (whether negligently or innocently made) by any person
        (whether party agrees to these Terms of Conditions or not) other than as
        expressly set out in these Terms and Conditions.
      </SecondaryParagraphText>

      <ParagraphText>
        15. Use of Personal Data and Data Protection
      </ParagraphText>

      <SecondaryParagraphText>
        15.1 The terms of the DUSK Privacy Policy are incorporated into these
        Terms and Conditions. You agree to the use of your personal information
        (if any) by DUSK in accordance with the terms of and for the purposes
        set forth in the DUSK Privacy Policy which can be found at{" "}
        <NavLink to="/privacy_policy">https://dusk.app/privacy_policy</NavLink>.
        Drinki Limited is the data controller for the purposes of the Data
        Protection Act 1998.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        15.2 By entering into the Premium Profile Agreement, the Client
        acknowledges and agrees that DUSK may collect certain Personal Data (as
        defined in the Data Protection Act 1998) relating to the Client and/or
        employees engaged by the Client. Subject to complying with statutory and
        regulatory obligations, DUSK may use such Personal Data for a number of
        purposes, including but not limited to the following:
      </SecondaryParagraphText>

      <ol type="a">
        <li>
          <SecondaryParagraphText>
            to process the Client’s order;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            to provide the Services to the Client;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            to conduct market research and statistical analysis;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            to monitor, improve and protect our Premium Profile content, product
            and services;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            to enable DUSK to comply with any legal or regulatory requirements;
            to protect or enforce our rights or the rights of any third party;
            in the detection and prevention of fraud and other crime;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            to send to the Client periodic newsletters and other information
            about Premium Profile products and services operated by DUSK or
            other products and/or services offered by DUSK unless the Client has
            chosen to opt-out from receiving such communications;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            to send direct marketing to the Client. This may include
            communications by post, telephone or email, about DUSK’s content,
            products and services, events and special offers, while Client is
            engaged in a contractual relationship with DUSK, unless the Client
            has chosen to opt-out from receiving such communications
          </SecondaryParagraphText>
        </li>
      </ol>
      <SecondaryParagraphText>
        15.3 DUSK reserves the right to vary or amend the Privacy Policy from
        time to time. Any amendments to the Privacy Policy will be reflected on
        the Website and recommends to the Client to regularly check its website
        accordingly.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        15.4 The Client may choose to opt-out from receiving any communications
        from DUSK at any time by sending an email to DUSK at the following
        address:<a href="mailto:support@dusk.app">support@dusk.app</a> or by
        clicking on the “unsubscribe link” provided in each communication sent
        by DUSK.
      </SecondaryParagraphText>
      <ParagraphText>16. Proprietary Rights</ParagraphText>
      <SecondaryParagraphText>
        16.1 <span className="b">Intellectual Property Rights.</span> DUSK
        retains all rights, title and interest in the Services and all related
        intellectual property and proprietary rights. The Services and all third
        party software provided with the Services are protected by applicable
        copyright, trade secret, industrial and other intellectual property
        laws.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        16.2 <span className="b">Restrictions.</span> The Client will not (a)
        copy, operate or use the Services in excess of the level of Services
        purchased, as set out in the Purchase Order and detailed in the
        Schedule; (b) modify, delete or remove any ownership, title, trademark
        or copyright notices while using the Services; (c) dissemble, reverse
        engineer, decompile or otherwise attempt any product source code from
        the object code; (d) distribute rent, lease, sublicense or provide the
        Premium Profile product Services to any third party or use it in a
        service bureau, outsourcing environment, or for the processing of third
        party data; (e) attempt to disable or circumvent any of the licensing
        mechanism within the Premium Profile product Services.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        16.3 A breach of this Clause 16 will be considered to be a material
        breach of the Premium Profile Agreement.
      </SecondaryParagraphText>

      <ParagraphText>17. Confidentiality</ParagraphText>
      <SecondaryParagraphText>
        17.1 “<span className="b">Confidential Information</span>” means all
        confidential information (however recorded or preserved) disclosed by a
        party to the other party, including DUSK’s affiliates, where applicable,
        whether before or after the date of this Premium Profile Agreement, in
        connection with the provision of the Services, including but not limited
        to:
      </SecondaryParagraphText>
      <ol type="a">
        <li>
          <SecondaryParagraphText>
            the existence and terms of this Premium Profile Agreement; and
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            any information that would be regarded as confidential by a
            reasonable person relating to the business, affairs, customers,
            clients, suppliers, plans, intentions or market opportunities of the
            disclosing party, as well as the operations, processes, product
            information, know-how, designs, trade secrets or software of the
            disclosing party; and
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            any information developed by the parties in the course of carrying
            out this Premium Profile Agreement.
          </SecondaryParagraphText>
        </li>
      </ol>

      <SecondaryParagraphText>
        17.2 Each party undertakes that it shall not at any time during this
        Premium Profile Agreement, and for a period of three (3) years after
        termination of this Premium Profile Agreement, disclose to any person
        any Confidential Information concerning the business, affairs,
        customers, clients or suppliers of the other party, except as permitted
        by Clause 17.3.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        17.3 Each party may disclose the other party's Confidential Information:
      </SecondaryParagraphText>

      <ol type="a">
        <li>
          <SecondaryParagraphText>
            to its employees, officers, representatives or advisers who need to
            know such information for the purposes of exercising the party's
            rights or carrying out its obligations under or in connection with
            this Premium Profile Agreement. Each party shall ensure that its
            employees, officers, representatives or advisers to whom it
            discloses the other party's Confidential Information comply with
            this Clause 17; and
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            as may be required by law, a court of competent jurisdiction or any
            governmental or regulatory authority.
          </SecondaryParagraphText>
        </li>
      </ol>

      <SecondaryParagraphText>
        17.4 No party shall use any other party's Confidential Information for
        any purpose other than to exercise its rights and perform its
        obligations under or in connection with this Premium Profile Agreement.
      </SecondaryParagraphText>

      <ParagraphText>18. Force Majeure</ParagraphText>
      <SecondaryParagraphText>
        Neither party shall be in breach of this Premium Profile Agreement nor
        liable for delay in performing, or failure to perform, any of its
        obligations if such delay or failure result from events, circumstances
        or causes beyond its reasonable control. In such circumstances the
        affected party shall be entitled to a reasonable extension of the time
        for performing such obligations. If the period of delay or
        non-performance continues for one (1) month, the party not affected may
        terminate this Premium Profile Agreement by giving one (1) month written
        notice to the affected party.
      </SecondaryParagraphText>

      <ParagraphText>19. Governing Law and Jurisdiction</ParagraphText>
      <SecondaryParagraphText>
        These Terms and Conditions shall be governed by and construed in all
        respects in accordance with English law and the parties agree to submit
        to the exclusive jurisdiction of the English courts.
      </SecondaryParagraphText>

      <ParagraphText>20. Publisher</ParagraphText>
      <SecondaryParagraphText>
        Drinki Limited, a company registered in England and Wales under company
        number 08704401, with its registered office at Flat 3c St Johns Wharf,
        104-106 Wapping High Street, London, E1W 2PR, United Kingdom and with
        VAT number GB180391511.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        Drinki Limited Premium Profile Agreement Confidential
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        *DUSK is a registered trademark of Drinki Limited, a company
        incorporated in England and Wales with company number 08704401 and with
        registered office 77 Leonard Street, London, EC2A 4QS with an astrix on
        the first DUSK
      </SecondaryParagraphText>
    </div>
  </div>
);

export default PremiumTermsBlock;
