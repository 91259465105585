import React from "react";
import cx from "classnames";
import "./NewHero.scss";

const NewHero = ({ bg = "black", fullHeight, size, className, children }) => {
  return (
    <section
      className={cx(
        "hero new-hero",
        fullHeight ? "full-height" : undefined,
        size ? `is-${size}` : undefined,
        `hero-${bg}`,
        className
      )}
    >
      <div className="hero-body">
        <div className="container">{children}</div>
      </div>
    </section>
  );
};

export default NewHero;
