import React from "react";
import Columns from "../../shared/Columns";
import ImageForBar from "./ImageForBar";
import { TitleText } from "../../shared/typography/Typography";

const RecommendedBar = ({ data }) => {
  // TO DO revise this upon receiving new format for recommended bars
  const { related } = data;
  return (
    <section className="section">
      <div className="container">
        <TitleText align="centered">Related Bars</TitleText>
        <Columns isMultiline>
          {related &&
            related.map(each => (
              <div
                className="column has-text-centered is-one-third is-half-tablet"
                key={each.key}
              >
                <ImageForBar
                  images={[each.image_url, each.image_url, each.image_url]}
                  address={each.address_1}
                />
              </div>
            ))}
        </Columns>
      </div>
    </section>
  );
};

export default RecommendedBar;
