import React from "react";
import Button from "./Button";
import "./MobileBottomButton.scss";

const MobileButtonBottom = ({ to }) => {
  return (
    <div className="container full-screen-bottom">
      <a href={to}>
        <Button className="bottom-button">View on DUSK</Button>
      </a>
    </div>
  );
};

export default MobileButtonBottom;
