import React from "react";
// import Button from "../../shared/Button";
import { TertiaryParagraphText } from "../../shared/typography/Typography";
import "./EditDetail.scss";

const EditDetail = ({ data }) => {
  // TO DO update when confirmed if view in dusk button should be included
  const {
    // edit: { summary, share_url }
    edit: { summary },
  } = data;
  return (
    <div className="container">
      <section className="section edit-page">
        <TertiaryParagraphText>{summary}</TertiaryParagraphText>
      </section>
    </div>
  );
};

export default EditDetail;
