import React from "react";
import NewHero from "shared/NewHero";
import { HeroButton } from "shared/Button";
import PhoneFrameImage from "shared/PhoneFrameImage";
import "./HeroSection.scss";

const HeroSection = () => {
  return (
    <NewHero size="large">
      <div className="columns">
        <div className="column is-5-desktop is-6">
          <h1 className="dusk-h1-outline-100 dusk-m-bot">
            <span className="has-text-white">DUSK</span> ACCELERATOR
          </h1>
          <p className="dusk-p-18 mb-3">
            We’re on the scout for the cream of the crop to support fresh, up-and-coming UK drinks brands with small budgets, small distribution and small stock.   
          <br /> <br />
          Via the DUSK platform and our connected network of users, we believe in supporting the industry from within, promoting growth, discovery and recovery.
          </p>
          <div className="has-text-centered-mobile">
            <a href="https://dusk-feedback.typeform.com/to/TZTTuetY" target="_blank" rel="noopener noreferrer">
              <HeroButton>Apply here</HeroButton>
            </a>
          </div>
        </div>
        <div className="column is-offset-3-desktop is-4-desktop is-6 is-flex image-control">
          <PhoneFrameImage className="" src={require("assets/images/accelerator-listview.png")} />
        </div>
      </div>
    </NewHero>
  );
};

export default HeroSection;
