import React from "react";
import { Link as RRLink } from "react-router-dom";
import { Link } from "../../shared/typography/Link";

import {
  ParagraphText,
  LinkTextTitle,
} from "../../shared/typography/Typography";
import Image from "../../shared/Image";
import "./Footer.scss";

const Links = {
  home: [
    { title: "SUPPORT", to: "/support" },
    { title: "GUIDES", to: "https://blog.dusk.app", external: true },
    { title: "PRIVACY POLICY", to: "/privacy-policy" },
  ],
  workWithUs: [
    { title: "VENUES", to: "/for-bars" },
    { title: "DRINKS BRANDS", to: "/for-brands" },
    { title: "REWARDS PARTNERS", to: "/for-rewards" },
    { title: "DUSK ACCELERATOR", to: "/dusk-accelerator" },
  ],
};

const Footer = () => (
  <footer className="footer footer-style">
    <div className="content has-text-centered">
      <div className="container">
        <div className="columns">
          <div className="column is-one-third">
            <RRLink to="/">
              <LinkTextTitle className="footer-title">HOME</LinkTextTitle>
            </RRLink>
            <ul>
              {Links.home.map((each) => (
                <li className="dusk-link-item-hover mb-2" key={each.to}>
                  <Link footer={true} to={each.to} external={each.external}>
                    {each.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="column is-one-third">
            <LinkTextTitle className="footer-title">WORK WITH US</LinkTextTitle>
            <ul>
              {Links.workWithUs.map((each) => (
                <li className="dusk-link-item-hover mb-2" key={each.to}>
                  <Link footer={true} to={each.to} external={each.external}>
                    {each.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="column is-one-third">
            <div className="social-stack">
              <a
                className="social"
                href="https://www.instagram.com/dusk.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  srcs={[
                    require("../../assets/images/iconInstagram.png"),
                    require("../../assets/images/iconInstagram@2x.png"),
                    require("../../assets/images/iconInstagram@3x.png"),
                  ]}
                  alt="Instagram"
                />
              </a>
              <a
                className="social"
                href="https://www.tiktok.com/@dusk.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  srcs={[
                    require("../../assets/images/iconTiktok.png"),
                    require("../../assets/images/iconTiktok@2x.png"),
                    require("../../assets/images/iconTiktok@3x.png"),
                  ]}
                  alt="Tiktok"
                />
              </a>
              <a
                className="social"
                href="https://twitter.com/dusk_app?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  srcs={[
                    require("../../assets/images/iconTwitter.png"),
                    require("../../assets/images/iconTwitter@2x.png"),
                    require("../../assets/images/iconTwitter@3x.png"),
                  ]}
                  alt="Twitter"
                />
              </a>
            </div>

            <div>
              <a
                className="is-block"
                href="https://apps.apple.com/gb/app/dusk/id824241550"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  srcs={[
                    require("../../assets/images/iconAppStore.png"),
                    require("../../assets/images/iconAppStore@2x.png"),
                    require("../../assets/images/iconAppStore@3x.png"),
                  ]}
                  alt="Download on the App Store"
                  width={121}
                  height={37}
                />
              </a>
              <a
                className="is-block"
                href="https://play.google.com/store/apps/details?id=com.drinki.app&hl=en_GB"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  srcs={[
                    require("../../assets/images/iconGooglePlay.png"),
                    require("../../assets/images/iconGooglePlay@2x.png"),
                    require("../../assets/images/iconGooglePlay@3x.png"),
                  ]}
                  alt="Download on the Play Store"
                  width={120}
                  height={37}
                />
              </a>
            </div>
          </div>
        </div>

        <ParagraphText align="centered" className="footer-copyright">
          &copy; DUSK™ All Rights Reserved
        </ParagraphText>
      </div>
    </div>
  </footer>
);

export default Footer;
