import React from "react";
import MultiImageBanner from "shared/MultiImageBanner";
import Image from "shared/Image";

const FeaturedIn = () => (
  <MultiImageBanner title="AS SEEN IN" columnContainerClass="is-mobile">
    <Image
      srcs={[
        require("assets/images/iconTimeout.png"),
        require("assets/images/iconTimeout@2x.png"),
        require("assets/images/iconTimeout@3x.png"),
      ]}
      alt="Timeout"
      width={132}
      height={51}
    />
    <Image
      srcs={[
        require("assets/images/iconBusinessInsider.png"),
        require("assets/images/iconBusinessInsider@2x.png"),
        require("assets/images/iconBusinessInsider@3x.png"),
      ]}
      alt="Business Insider"
      width={130}
      height={41}
    />
    <Image
      srcs={[
        require("assets/images/londonEveningStandard.png"),
        require("assets/images/londonEveningStandard@2x.png"),
        require("assets/images/londonEveningStandard@3x.png"),
      ]}
      alt="Evening Standard"
      width={177}
      height={24}
    />
    <Image
      srcs={[
        require("assets/images/iconBloomberg.png"),
        require("assets/images/iconBloomberg@2x.png"),
        require("assets/images/iconBloomberg@3x.png"),
      ]}
      alt="Bloomberg"
      height={31}
      width={153}
    />
    <Image
      srcs={[
        require("assets/images/iconBuzzfeed.png"),
        require("assets/images/iconBuzzfeed@2x.png"),
        require("assets/images/iconBuzzfeed@3x.png"),
      ]}
      alt="BuzzFeed"
      width={153}
      height={27}
    />
  </MultiImageBanner>
);

export default FeaturedIn;
