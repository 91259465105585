import React from "react";

import Loading from "shared/Loading";

const Support = () => {
  window.location.replace("https://help.dusk.app/");
  return <Loading />;
};
// const Support = () => (
//   <Fragment>
//     <Helmet>
//       <title>Support | DUSK</title>
//     </Helmet>
//     <Hero>
//       <h1 className="dusk-h1-outline-100 has-text-centered dusk-m-top-l">
//         GET IN TOUCH
//       </h1>
//     </Hero>
//     <ContactUs />
//   </Fragment>
// );

export default Support;
