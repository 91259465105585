import React from "react";

const Image = ({ className, srcs, width, height, alt }) => {
  const srcSet = `${srcs[0]} 1x, ${srcs[1]} 2x, ${srcs[2]} 3x`;
  return (
    <img
      className={className}
      src={srcs[0]}
      srcSet={srcSet}
      alt={alt}
      width={width}
      height={height}
    />
  );
};

export default Image;
