import React from "react";
import { TabletOnwards, UpToTablet } from "shared/MediaQuery";
import PhoneFrameImage from "shared/PhoneFrameImage";
import "./HowToUseSection.scss";

/* prettier-ignore */
const featuresOne = [
  { icon: require('assets/images/iconTrack.png'), title: 'TRACK CONSUMER JOURNEY', description: 'Drive trial, incentivise purchase, and retarget in the off-trade. Track spend across reported and actual behaviour.' },
  { icon: require('assets/images/iconWeigh.png'), title: 'SCALABLE & FLEXIBLE', mobileTitle: 'SCALE & FLEX YOUR ACTIVATION', description: 'Multiple venues can be activated without any physical installations. We are integrated with leading managed customers and independents.' },
  { icon: require('assets/images/iconSale.png'), title: 'INCREASE RATE OF SALE', description: 'Driving trial via Dusk is proven to increase sales with follow-up offers training consumers to purchase.' },
  { icon: require('assets/images/iconChart.png'), title: 'DATA DRIVEN', mobileTitle: 'MEASURE & TARGET WITH REALTIME DATA', description: 'Realtime demographic data for every serve. Measure mindset of target audiences and efficacy of spend.' },
  { icon: require('assets/images/iconEye.png'), title: 'BRAND AWARENESS', mobileTitle: 'DRIVE BRAND AWARENESS', description: 'Millions of impressions a month on hyper-targeted audiences. A/B test brand messaging and targeting.' },
]
/* prettier-ignore */
const featuresTwo = [
  { icon: require('assets/images/iconGroup.png'), title: 'VOLUME TRIAL', mobileTitle: 'DRIVE VOLUME TRIAL', description: 'Drive trial and achieve 1000s of samples of your perfect serves/week.' },
  { icon: require('assets/images/iconIncrease.png'), title: 'BENEFIT CUSTOMERS', mobileTitle: 'IMPROVE CUSTOMER RELATIONSHIPS', description: 'Improve relationships, drive sales, and incentivise purchase in both on-trade and off-trade.' },
  { icon: require('assets/images/iconTestLiquid.png'), title: 'TEST NEW LIQUIDS', mobileTitle: 'TEST NEW SERVES & LIQUIDS', description: 'Test new serves and liquids using our live rating systems and qualitative feedback channels.' },
  { icon: require('assets/images/icon360.png'), title: '360 CAMPAIGN', mobileTitle: '360 CAMPAIGN TO FIT YOUR MARKETING PLAN', description: 'Use Dusk in-line with other marketing activations.' },
]

const Feature = ({ icon, title, mobileTitle, description }) => (
  <div className="columns is-mobile" id="how-to-use-feature">
    <div className="column is-2 is-flex is-align-items-flex-start-tablet is-align-items-center-mobile is-justify-content-center">
      <img
        className="use-icon"
        style={{ objectFit: "contain" }}
        src={icon}
        alt={title}
      />
    </div>
    <div className="column is-flex is-justify-content-center is-flex-direction-column">
      <TabletOnwards>
        <p
          className="dusk-condensed-p-30"
          style={{ marginBottom: "12px", lineHeight: 1.33 }}
        >
          {title}
        </p>
      </TabletOnwards>
      <UpToTablet>
        <p className="dusk-condensed-p-20">{mobileTitle || title}</p>
      </UpToTablet>
      <p className="dusk-light-p-16 is-hidden-mobile">{description}</p>
    </div>
  </div>
);

const HowToUseSection = () => {
  return (
    <div className="section use-section dusk-bg-dark">
      <div className="container">
        <div className="columns use-container">
          <div className="column use-image-container is-hidden-mobile">
            <div className="image">
              <PhoneFrameImage
                shadow
                className="image-override"
                src={require("assets/images/brandPageAppOne.png")}
              />
            </div>
          </div>
          <div className="column use-text-container" id="right">
            {featuresOne.map((each) => (
              <Feature key={each.title} {...each} />
            ))}
          </div>
        </div>
        <div className="is-hidden-tablet use-image-standalone-container">
          <PhoneFrameImage
            shadow
            className="use-image-standalone"
            src={require("assets/images/brandPageAppOne.png")}
          />
        </div>
        <div className="columns use-container">
          <div className="column use-text-container" id="left">
            <h1
              className="dusk-h1-80-64 has-text-centered-mobile"
              style={{ marginBottom: "2rem" }}
            >
              YOUR KPIs,
              <br />
              YOUR ACTIVATION
            </h1>
            {featuresTwo.map((each) => (
              <Feature key={each.title} {...each} />
            ))}
          </div>
          <div className="column use-image-container is-hidden-mobile">
            <div className="image" id="right">
              <PhoneFrameImage
                shadow
                className="image-override"
                src={require("assets/images/brandPageAppTwo.png")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToUseSection;
