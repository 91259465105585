import React from "react";
import Button from "../../shared/Button";
import IconDescription from "../../shared/IconDescription";
import { TertiaryParagraphText } from "../../shared/typography/Typography";
import "./BarDetail.scss";

const BarDetail = ({ data }) => {
  const { description, nearest_station, free_drink, share_url, offer } = data;
  return (
    <section className="section">
      <div className="container">
        <div className="columns reverse-wrap">
          <div className="column bar-detail-description">
            <TertiaryParagraphText>{description}</TertiaryParagraphText>
          </div>
          <div className="column is-two-fifths">
            <a href={share_url}>
              <Button className="button-full">View on DUSK</Button>
            </a>
            <div className="bar-detail-offers">
              <IconDescription type="tube" body={nearest_station} />
              {free_drink ? (
                <IconDescription type="drink" body={free_drink} />
              ) : null}
              {offer ? <IconDescription type="offer" body={offer} /> : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BarDetail;
