import React, { useState, useMemo } from "react";
import { TitleText, ParagraphText } from "../../shared/typography/Typography";
import Button from "../../shared/Button";
import Columns from "../../shared/Columns";
import { validatePhoneNumber, apiTrackCustomerData } from "../../shared/utils";
import TrackingInputField from "./TrackingInputField";
import PeopleCounter from "./PeopleCounter";

const TrackingForm = ({ slug, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [partySize, setPartySize] = useState(0);

  const onNameChange = ({ target: { value } }) => setName(value);
  const onNumberChange = ({ target: { value } }) => setNumber(value);
  const onAgreedChange = ({ target: { checked } }) => setAgreed(checked);

  const isFormValid = () => {
    if (!name || name.length < 3) {
      setErrorMessage("Please enter your full name.");
      return false;
    }

    if (!number || !validatePhoneNumber(number)) {
      setErrorMessage("Please enter a valid phone number.");
      return false;
    }

    if (!agreed) {
      setErrorMessage(
        "Please read and agree to our privacy policy before continuing."
      );
      return false;
    }

    setErrorMessage(null);
    return true;
  };

  useMemo(() => {
    if (errorMessage) {
      isFormValid();
    }
    // eslint-disable-next-line
  }, [name, number, agreed, errorMessage, isFormValid]);

  const submitForm = () => {
    if (!isFormValid()) {
      return;
    }

    const payload = {
      slug,
      name,
      number,
      partySize,
    };

    setIsLoading(true);

    apiTrackCustomerData(payload)
      .then(() => {
        setIsLoading(false);
        onSubmit();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const buttonClasses = ["btn-white"];
  if (isLoading) {
    buttonClasses.push("is-loading");
  }

  return (
    <Columns className="dusk-negate-t-padding" id="covid-tracking-form">
      <div className="column is-offset-one-fifth is-three-fifths has-text-centered">
        <TitleText className="dusk-m-bot">Enter your details</TitleText>
        <TrackingInputField
          inputType="name"
          onChange={onNameChange}
          value={name}
        >
          <ParagraphText align="left">Legal Name</ParagraphText>
        </TrackingInputField>

        {/* <TrackingInputField
          inputType="email"
          onChange={onEmailChange}
          value={email}
        >
          <ParagraphText align="left">Email Address</ParagraphText>
        </TrackingInputField> */}

        <TrackingInputField
          inputType="phone"
          onChange={onNumberChange}
          value={number}
        >
          <ParagraphText align="left">Phone Number</ParagraphText>
        </TrackingInputField>

        <ParagraphText align="left">
          How many people are you with?
        </ParagraphText>
        <PeopleCounter count={partySize} onChange={setPartySize} />

        <label className="checkbox">
          <input onChange={onAgreedChange} type="checkbox" />I agree to the{" "}
          <a
            href="/track-trace-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontFamily: "Gotham-Bold" }}
          >
            Privacy Policy
          </a>
        </label>
        <br />
        {errorMessage && (
          <ParagraphText align="centered" className="error-text">
            {errorMessage}
          </ParagraphText>
        )}
        <Button onClick={submitForm} className={buttonClasses.join(" ")}>
          Submit
        </Button>
      </div>
    </Columns>
  );
};

export default TrackingForm;
