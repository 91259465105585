import React, { Fragment, PureComponent } from "react";
import Hero from "../shared/Hero";
import CollectionDetail from "./collectionPage/CollectionDetail";
import GetTheApp from "../shared/GetTheApp";
import Loading from "../shared/Loading";
import ListingImage from "../shared/ListingImage";
import Columns from "../shared/Columns";
import { TitleText } from "../shared/typography/Typography";

import { withRouter } from "react-router-dom";

import { Helmet } from "react-helmet";

import "./CollectionPage.scss";

import { apiGetCollection } from "../shared/utils";

class CollectionPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      data: null
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { slug }
      }
    } = this.props;
    apiGetCollection(slug)
      .then(result => {
        this.setState({
          data: result.data.group,
          isLoading: false,
          error:
            result.status !== 200
              ? "We couldn't find any information on this collection"
              : null
        });
      })
      .catch(error => {
        if (error.response) {
          this.setState({
            error:
              error.response.data.fail ||
              "We couldn't find any information on this collection",
            isLoading: false,
            data: null
          });
        } else {
          this.setState({
            error: "We couldn't find any information on this collection",
            isLoading: false,
            data: null
          });
        }
      });
  }

  render() {
    const { isLoading, data, error } = this.state;
    if (isLoading) return <Loading />;
    if (error || !data.venues.length)
      return (
        <Fragment>
          <Helmet>
            <title>404 | DUSK</title>
          </Helmet>

          <Hero background={null} fade bottomAlign>
            <TitleText className="dusk-m-bot-full">404</TitleText>
          </Hero>
          <section className="section">
            <div className="container">
              {error || "We couldn't find any information on this collection"}
            </div>
          </section>
        </Fragment>
      );

    const { tagline, description, image_url, name, venues } = data;
    return (
      <Fragment>
        <Helmet>
          <title>{name || "Bar"} | DUSK</title>
        </Helmet>

        <Hero background={image_url} fade bottomAlign>
          <TitleText className="dusk-m-bot-full">{tagline}</TitleText>
        </Hero>
        <Fragment>
          <CollectionDetail data={{ description }} />
          {venues.length ? (
            <Columns isMultiline>
              {venues.map((each, index) => (
                <div
                  className={`column has-text-centered is-one-third is-half-tablet padding-special`}
                  key={index}
                >
                  <ListingImage
                    images={[each.image_url, each.image_url, each.image_url]}
                    name={each.name}
                    description={each.description}
                    slug={each.slug}
                  />
                </div>
              ))}
            </Columns>
          ) : null}
        </Fragment>
        <GetTheApp mobileReversal={true} />
      </Fragment>
    );
  }
}

export default withRouter(CollectionPage);
