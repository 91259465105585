import React, { useRef } from "react";

import { Helmet } from "react-helmet";

import HeroSection from "./forBrands/HeroSection";
import HowItWorks from "shared/HowItWorks";
import HowToUseSection from "./forBrands/HowToUseSection";
import RequestOurDeck from "./forBrands/RequestOurDeck";
import BrandTestimonial from "shared/BrandTestimonial";
import BrandLogoCarousel from "shared/BrandLogoCarousel";

const ForBrands = () => {
  const featureRef = useRef(null);
  return (
    <>
      <Helmet>
        <title>For Brands | DUSK</title>
      </Helmet>
      <HeroSection featureRef={featureRef} />
      <HowItWorks />
      <HowToUseSection />
      <BrandTestimonial />
      <BrandLogoCarousel />
      <RequestOurDeck innerRef={featureRef} />
    </>
  );
};

export default ForBrands;
