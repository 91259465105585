import React, { PureComponent } from "react";

import querystring from "qs";

import { ParagraphText } from "../../shared/typography/Typography";
import { InputField } from "../../shared/Input";
import Button from "../../shared/Button";
import { validateEmail, apiCallUnsubscribe } from "../../shared/utils";

import { withRouter } from "react-router-dom";

class UnsubscribeBlock extends PureComponent {
  state = {
    email: "",
    error: null,
    sending: false,
    sent: false,
  };

  componentDidMount() {
    const rawQueryString = this.props.location.search;
    const queryStr = rawQueryString.startsWith("?")
      ? rawQueryString.substr(1)
      : rawQueryString;

    const qs = querystring.parse(queryStr);

    const loadedEmail = qs.mp_email || qs.md_email || qs.email;

    if (loadedEmail) {
      this.setState({
        email: loadedEmail,
      });
    }
  }

  _emailChanged = evt => {
    this.setState({
      email: evt.target.value,
    });
  };

  _sendForm = email => {
    apiCallUnsubscribe(email).then(
      () => {
        this.setState({
          email: "",
          sending: false,
          sent: true,
        });
      },
      err => {
        this.setState({
          sending: false,
          sent: false,
          error:
            err?.response?.data.error ??
            "An unknown error has occurred. Please contact support to unsubscribe.",
        });
      }
    );
  };

  _sendClicked = () => {
    const { email, sending, sent } = this.state;

    if (sending || sent) return;

    if (!email || !validateEmail(email)) {
      this.setState({ error: "Please enter a valid email." });
      return;
    }

    this.setState({
      sending: true,
      error: null,
    });

    this._sendForm(email);
  };

  render() {
    const { email, error, sending, sent } = this.state;

    var className = "";
    if (sending) {
      className = "is-loading";
    } else if (sent) {
      className = "is-success";
    }

    var buttonText = "Unsubscribe me";
    if (sending) {
      buttonText = "";
    } else if (sent) {
      buttonText = "Done";
    }

    return (
      <div className="section dusk-negate-t-padding">
        <div className="container has-text-centered">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <ParagraphText>
                But if you really have to then confirm your email address to be
                removed from our mailing list.
              </ParagraphText>
              <InputField
                className={"dusk-m-top-l" + (error ? "" : " dusk-m-bot")}
                inputType="email"
                value={email}
                onChange={this._emailChanged}
              />
              {error ? (
                <ParagraphText className="error-text has-text-left">
                  {error}
                </ParagraphText>
              ) : null}
              <Button className={className} onClick={this._sendClicked}>
                {buttonText}
              </Button>
            </div>
            <div className="column" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UnsubscribeBlock);
