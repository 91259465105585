import React from "react";
// import { NavLink } from "react-router-dom";
import NewHero from "shared/NewHero";
import { HeroButton } from "shared/Button";

const HeroSection = () => {
  return (
    <NewHero size="large">
      <div className="columns">
        {/* <div className="column is-5-desktop is-6 has-text-centered-mobile"> */}
        <div className="column is-4-desktop is-5 has-text-centered-mobile">
          <h1 className="dusk-h1-outline-100 dusk-m-bot">
            {/* GET <span className="has-text-white">REWARDED</span> FOR GOING OUT */}
            <span className="has-text-white">THE FREE DRINK APP</span>
          </h1>
          <p className="dusk-p-18 mb-3">
            Get free drinks and unlock rewards every time you explore with DUSK.
          </p>
          <div className="is-hidden-mobile">
            <a
              href="https://share.dusk.app/GPNmCAgauhb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HeroButton>Download DUSK</HeroButton>
            </a>
          </div>
          <div className="is-hidden-tablet">
            <a
              href="https://share.dusk.app/download"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HeroButton>Download DUSK</HeroButton>
            </a>
          </div>
        </div>
        <div className="column is-offset-3-desktop is-4-desktop is-6 is-flex image-control">
          <video loop muted autoPlay playsInline>
            <source
              src={require("assets/videos/videoHome.mp4")}
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </NewHero>
  );
};

export default HeroSection;
