import React from "react";
import {
  SecondaryParagraphText,
  ParagraphText,
} from "../../shared/typography/Typography";

import "./PrivacyPolicyBlock.scss";

const TrackTracePrivacyPolicyBlock = () => (
  <div className="section privacy-policy">
    <div className="container">
      {/* <TitleText align="centered" className="dusk-m-bot">
        Drinki Limited.
      </TitleText> */}

      {/* <SubtitleText>Introduction</SubtitleText> */}
      <ParagraphText>Introduction</ParagraphText>

      <SecondaryParagraphText>
        DUSK (being a registered trademark of) Drinki Limited, a company
        registered in England and Wales under company number 08704401, with its
        registered office at Flat 3c St Johns Wharf, 104-106 Wapping High
        Street, London, E1W 2PR, (<span class="b">DUSK</span>)), complies with
        all data protection and privacy laws in the countries and territories in
        which we operate when dealing with personal information. Personal
        information is information about an identifiable individual.
        <br />
        <br />
        This Policy sets out how DUSK will collect, use, disclose and protect
        your personal information. This Policy does not limit or exclude any of
        your statutory or other legal rights.
      </SecondaryParagraphText>

      <ParagraphText>COVID-19 – Guest Check in data.</ParagraphText>

      <SecondaryParagraphText>
        Personal information collected on customers checking in to this venue
        will only be used for the purpose of contact-tracing for COVID-19 and
        will not be used for any other purpose. It will only be made available
        to authorised third parties for the purpose of contact tracing, or as
        otherwise required by law. In the event that contact tracing measures
        are ended in the UK or any other territory in which you have completed a
        check in, all contact-tracing personal information will be deleted.
        <br />
        <br />
        All Check In data is deleted on a rolling 21 day basis in all countries
        and territories. DUSK shall securely store the Customer data in an
        encrypted format in Amazon Cloud (AWS).
      </SecondaryParagraphText>

      <ParagraphText>What personal information do we collect?</ParagraphText>

      <SecondaryParagraphText>
        For the purpose of contact-tracing for COVID-19 the personal information
        we collect about shall be limited to your full name, mobile phone number
        and the venue that you have checked in with.
        <br />
        <br />
        DUSK will not disclose, move, access, process or other use this personal
        information except as provided in our terms of service. We require
        customers to comply with applicable privacy and data protection laws.
      </SecondaryParagraphText>

      <ParagraphText>
        Who do we collect your personal information from?
      </ParagraphText>

      <SecondaryParagraphText>
        We collect personal information about you solely from you and only
        through this registration process.
      </SecondaryParagraphText>

      <ParagraphText>How we use your personal information?</ParagraphText>

      <SecondaryParagraphText>
        We will use your personal information only:
      </SecondaryParagraphText>

      <ul>
        <li>
          <SecondaryParagraphText>
            to verify your identity
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            notify the National Health Service (NHS) of the date and time of
            your registration with any specific venue
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            for any purpose ancillary to the above that is for the purpose of
            contact-tracing for COVID-19
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            for any other purpose authorised by you or legally permissible.
          </SecondaryParagraphText>
        </li>
      </ul>

      <ParagraphText>Disclosing your personal information</ParagraphText>

      <SecondaryParagraphText>
        We may disclose your personal information only to:
      </SecondaryParagraphText>
      <ul>
        <li>
          <SecondaryParagraphText>the NHS</SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            any business that supports our infrastructure and maintains our
            underlying IT system or data centre that we use to provide these
            services
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            person who can require us to supply your personal information (e.g.
            a regulatory authority)
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            any other person authorised by law (e.g. a law enforcement agency)
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            any other person authorised by you
          </SecondaryParagraphText>
        </li>
      </ul>

      <SecondaryParagraphText>
        A business that supports our services and products may be located
        outside the country in which you have entered your personal information.
        This may mean your personal information is held and processed outside
        the country you have provided it.
      </SecondaryParagraphText>

      <ParagraphText>Protecting your personal information</ParagraphText>

      <SecondaryParagraphText>
        We will all take reasonable steps to keep your personal information safe
        from loss, unauthorised activity, or other misuse.
      </SecondaryParagraphText>

      <ParagraphText>
        Accessing and correcting your personal information
      </ParagraphText>

      <SecondaryParagraphText>
        Subject to certain legitimate grounds for refusal, you have the right to
        access your readily retrievable personal information that we hold and to
        request a correction to your personal information. Before you exercise
        this right, we will need evidence to confirm that you are the individual
        to whom the personal information relates.
        <br />
        <br />
        In respect of a request for correction, if we think the correction is
        reasonable and we are reasonably able to change the personal
        information, we will make the correction. If we do not make the
        correction, we will take reasonable steps to note on the personal
        information that you requested the correction.
        <br />
        <br />
        If you want to exercise either of the above rights, email us at:
        support@dusk.app Your email should provide evidence of who you are and
        set out the details of your request (e.g. the personal information, or
        the correction, that you are requesting).
      </SecondaryParagraphText>

      <ParagraphText>Internet use</ParagraphText>

      <SecondaryParagraphText>
        While we take reasonable steps to maintain secure internet connections,
        if you provide us with personal information over the internet, the
        provision of that information is at your own risk.
        <br />
        <br />
        If you follow a link on our website or in the Service to another site,
        the owner of that site will have its own privacy policy relating to your
        personal information. We suggest you review that site’s privacy policy
        before you provide personal information.
      </SecondaryParagraphText>

      <ParagraphText>Changes to this Policy</ParagraphText>

      <SecondaryParagraphText>
        DUSK may change this Policy by uploading a revised Policy onto its
        website (
        <a
          href="https://www.dusk.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.dusk.app/
        </a>
        ) (the Website). Unless stated otherwise, the change will apply from the
        date that the revised Policy is uploaded.
      </SecondaryParagraphText>

      <ParagraphText>Contact us</ParagraphText>

      <SecondaryParagraphText>
        If you have any questions about this Policy or our privacy practices,
        you can contact us at:{" "}
        <a href="mailto:support@dusk.app">support@dusk.app</a>
        <br />
        <br />
        <br />
        Privacy Policy Last Updated 3 July 2020.
      </SecondaryParagraphText>
    </div>
  </div>
);

export default TrackTracePrivacyPolicyBlock;
