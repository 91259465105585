import React from 'react';
import cx from 'classnames';

import './Button.scss';
import { SubmitButtonText } from './typography/Typography';

const SubmitButton = ({ small, children, className, ...props }) => (
  <button
    className={cx('button is-dusk is-submit is-rounded-15', small ? 'is-small' : undefined, className)}
    {...props}
  >
    <SubmitButtonText small={small}>{children}</SubmitButtonText>
  </button>
);

export default SubmitButton;
