import React from "react";
import Ticker from "react-ticker";

/* prettier-ignore */
const Brands = [
  { title: "Bacardi", src: require("assets/images/rewardLogoBacardi.png") },
  { title: "Dashwater", src: require("assets/images/rewardLogoDashwater.png") },
  { title: "Freenow", src: require("assets/images/rewardLogoFreenow.png") },
  { title: "Gorillas", src: require("assets/images/rewardLogoGorillas.png") },
  { title: "Grind", src: require("assets/images/rewardLogoGrind.png") },
  { title: "Laine", src: require("assets/images/rewardLogoLaine.png") },
  { title: "Patch", src: require("assets/images/rewardLogoPatch.png") },
  { title: "Wild", src: require("assets/images/rewardLogoWild.png") },
  { title: "Yoppie", src: require("assets/images/rewardLogoYoppie.png") },
];

const Carousel = () => {
  return (
    <Ticker speed={10}>
      {({ index }) => (
        <div
          key={index}
          className="is-flex is-align-items-center is-justify-content-center px-3"
        >
          <img
            style={{
              width: 100,
              height: 100,
              objectFit: "contain",
              objectPosition: "center",
            }}
            src={Brands[index % Brands.length].src}
            alt={Brands[index % Brands.length].title}
          />
        </div>
      )}
    </Ticker>
  );
};

const RewardLogoCarousel = () => {
  return (
    <div className="py-3 bg-black has-text-centered">
      <Carousel />
    </div>
  );
};

export default RewardLogoCarousel;
