import React, { Fragment } from "react";
import Hero from "../../shared/Hero";
import { ParagraphText, TitleText } from "../../shared/typography/Typography";
import Button from "../../shared/Button";
import GetTheApp from "../../shared/GetTheApp";

const IntroContent = ({ barInfo, onProceedClick }) => (
  <Fragment>
    <Hero>
      <TitleText align="centered">Welcome to {barInfo.name}</TitleText>
    </Hero>
    <div className="section dusk-negate-t-padding" id="tracking-intro">
      <div className="container has-text-centered">
        <ParagraphText align="centered">
          Register your details for COVID-19 track and trace.
        </ParagraphText>
        <ParagraphText align="centered">
          We’ll securely store them, and you’ll only be contacted in event of an
          outbreak.
        </ParagraphText>
        <Button className="btn-white is-clearfix" onClick={onProceedClick}>
          Check in now
        </Button>
        <br />
        <div className="privacy-policy-container">
          <a
            href="/track-trace-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            See our Privacy Policy
          </a>
        </div>
      </div>
    </div>
    <GetTheApp />
  </Fragment>
);

export default IntroContent;
