import React, { Fragment } from "react";
import Helmet from "react-helmet";
import Hero from "../shared/Hero";
import { HeroText, ParagraphText } from "../shared/typography/Typography";
import "./NotFound.scss";

function NotFound() {
  return (
    <Fragment>
      <Helmet>
        <title>404 Page Doesn't Exist | DUSK</title>
      </Helmet>
      <Hero className="not-found-hero" bottomAlign={false} size="large">
        <HeroText className="dusk-m-bot" align="center">
          O-o-h child
        </HeroText>
        <ParagraphText>This page doesn't seem to exist.</ParagraphText>
        <div className="video-container">
          <iframe
            title="Not Found Video"
            type="text/html"
            className="video-iframe"
            src={"https://www.youtube.com/embed/sGpC5IvKaLA"}
            frameBorder="0"
          />
        </div>
      </Hero>
    </Fragment>
  );
}

export default NotFound;
