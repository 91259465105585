import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Image from "./Image";
import Tag from "./Tag";
import { truncateString } from "./utils";
import {
  TertiaryParagraphText,
  SecondaryTitleText
} from "./typography/Typography";
import "./ListingImage.scss";

const placeholder = [
  require("../assets/images/heroBarSecondary.jpg"),
  require("../assets/images/heroBarSecondary@2x.jpg"),
  require("../assets/images/heroBarSecondary@3x.jpg")
];

const ListingImage = ({ images, description, name, slug, tags }) => (
  <Fragment>
    <NavLink to={`/bar/${slug}`}>
      <Image
        className={`listing-image ${!images ? "blur" : ""}`}
        srcs={images ? images : placeholder}
        alt={name}
      />
    </NavLink>
    <NavLink to={`/bar/${slug}`}>
      <SecondaryTitleText className="listing-title">{name}</SecondaryTitleText>
    </NavLink>
    {tags && tags.length && (
      <div className="tags">
        {tags.map((each, index) => (
          <Tag key={index}>{each}</Tag>
        ))}
      </div>
    )}
    <TertiaryParagraphText className="listing-text" align="left">
      {truncateString(description)}
      {description.length > 256 ? (
        <NavLink className="read-more" to={`/bar/${slug}`}>
          read more
        </NavLink>
      ) : null}
    </TertiaryParagraphText>
  </Fragment>
);

export default ListingImage;
