import React from "react";

const PeopleCounter = ({ count, onChange }) => {
  return (
    <div className="people-counter-container">
      <div className="people-counter">
        {/* eslint-disable-next-line */}
        <a
          className="counter-btn"
          onClick={() => onChange(Math.max(0, count - 1))}
        >
          <img
            src={require("../../assets/images/counter-minus.svg")}
            alt="minus"
          />
        </a>
        <p>{count}</p>
        {/* eslint-disable-next-line */}
        <a className="counter-btn" onClick={() => onChange(count + 1)}>
          <img
            src={require("../../assets/images/counter-plus.svg")}
            alt="plus"
          />
        </a>
      </div>
    </div>
  );
};

export default PeopleCounter;
