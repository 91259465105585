import React from "react";
import PhoneFrameImage from "shared/PhoneFrameImage";
import { LightSection } from "shared/Section";
import LinkImage from "assets/images/homePageLink.png";

const LinkSection = () => {
  return (
    <LightSection>
      <div className="columns section-dusk-columns">
        <div className="column is-6 section-dusk-image-column">
          <div className="section-dusk-image-container">
            <PhoneFrameImage
              shadow
              className="image"
              src={LinkImage}
              alt="home page link"
            />
          </div>
        </div>
        <div className="column is-offset-1-desktop is-4-desktop is-6 section-dusk-text-column has-text-centered-mobile">
          <div style={{ maxWidth: 350 }}>
            <h1 className="dusk-h1-160">
              LINK.
            </h1>
            <p className="dusk-medium-p-20-14">
              Link your card and get earning DUSK points every time you spend at
              your favourite bars.
            </p>
            <p className="dusk-p-20">
              WE NEVER TAKE PAYMENTS, AND YOUR DATA IS SECURE.
            </p>
          </div>
        </div>
      </div>
    </LightSection>
  );
};

export default LinkSection;
