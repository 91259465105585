import React from "react";
import Columns from "../../shared/Columns";
import Button from "../../shared/Button"


const ApplyAccelerator = () => {
  return (
    <Columns>
      <div className="column has-text-centered">
        <div className="mx-3">
          <h1 className="dusk-h1-outline-80 mb-2">APPLY FOR THE 2023 ACCELERATOR</h1>
          <p className="dusk-p-16 mb-2">
            Sign up for more details and our onboarding pack.
          </p>
        </div>
        <a href="https://dusk-feedback.typeform.com/to/TZTTuetY" target="_blank" rel="noopener noreferrer">
          <Button>Apply here</Button>
        </a>
      </div>
    </Columns>
  );
}

export default ApplyAccelerator;
