import React, { Fragment } from "react";
import Hero from "../shared/Hero";
import { HeroText } from "../shared/typography/Typography";

import { Helmet } from "react-helmet";
import TrackTracePrivacyPolicyBlock from "./privacyPolicy/TrackTracePrivacyPolicyBlock";

const TrackTracePrivacyPolicy = () => (
  <Fragment>
    <Helmet>
      <title>Privacy Policy | DUSK</title>
    </Helmet>
    <Hero>
      <HeroText className="dusk-m-top-xl" align="centered">
        Privacy Policy
      </HeroText>
    </Hero>
    <TrackTracePrivacyPolicyBlock />
  </Fragment>
);

export default TrackTracePrivacyPolicy;
