import React from "react";
import PhoneFrameImage from "shared/PhoneFrameImage";
import { DarkSection } from "shared/Section";

const ImpressionSection = () => {
  return (
    <DarkSection className="mobile-small">
      <div className="is-hidden-tablet">
        <h1 className="dusk-h1-80 has-text-white mb-3 has-text-centered">
          DRIVE IMPRESSIONS TO YOUR TARGET AUDIENCE
        </h1>
      </div>
      <div className="columns section-dusk-columns">
        <div className="column is-6 section-dusk-image-column">
          <div className="section-dusk-image-container">
            <PhoneFrameImage
              shadow
              className="image"
              src={require("assets/images/rewardPageHome.png")}
              alt="reward page app"
            />
          </div>
        </div>
        <div className="column is-offset-1-desktop is-4-desktop is-6 section-dusk-text-column has-text-centered-mobile is-hidden-mobile">
          <h1 className="dusk-h1-80 has-text-white">
            DRIVE IMPRESSIONS TO YOUR TARGET AUDIENCE
          </h1>
        </div>
      </div>
    </DarkSection>
  );
};

export default ImpressionSection;
