import React from "react";
import cx from "classnames";
import "./Typography.scss";

const HeroText = ({ align, children, className, style }) => (
  <h1
    className={
      "text-hero" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
    style={style}
  >
    {children}
  </h1>
);

const HeroTextSmall = ({ align, children, className, style }) => (
  <h2
    className={
      "text-hero-small" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
  >
    {children}
  </h2>
);

const TitleText = ({ align, children, className, style }) => (
  <h2
    className={
      "text-title" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
  >
    {children}
  </h2>
);

const SecondaryTitleText = ({ align, children, className, style }) => (
  <h2
    className={
      "text-secondary-title" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
  >
    {children}
  </h2>
);

const TertiaryTitleText = ({ align, children, className, ...props }) => (
  <h2
    className={cx(
      "text-tertiary-title",
      align ? `has-text-${align}` : undefined,
      className
    )}
    {...props}
  >
    {children}
  </h2>
);

const SubtitleText = ({ align, children, className, style }) => (
  <h3
    className={
      "text-subtitle" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
  >
    {children}
  </h3>
);

const TagText = ({ align, children, className, style }) => (
  <span
    className={
      "text-tag" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
  >
    {children}
  </span>
);

const ButtonText = ({ align, children, className, small, ...props }) => (
  <span
    className={cx(
      "text-button",
      align ? `has-text-${align}` : undefined,
      small ? "is-small" : undefined,
      className
    )}
    {...props}
  >
    {children}
  </span>
);

const SubmitButtonText = ({ align, children, className, small, ...props }) => (
  <span
    className={cx(
      "text-submit-button",
      align ? `has-text-${align}` : undefined,
      small ? "is-small" : undefined,
      className
    )}
    {...props}
  >
    {children}
  </span>
);

const ParagraphText = ({ align, children, className, style, ...props }) => (
  <p
    className={
      "text-paragraph" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
    {...props}
  >
    {children}
  </p>
);

const ParagraphTextBook = ({ align, children, className, ...props }) => (
  <p
    className={
      "text-paragraph-book" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
    {...props}
  >
    {children}
  </p>
);

const SecondaryParagraphText = ({ align, children, className, style }) => (
  <p
    className={
      "text-secondary-paragraph" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
  >
    {children}
  </p>
);

const TertiaryParagraphText = ({ align, children, className, style }) => (
  <p
    className={
      "text-tertiary-paragraph" +
      (align ? ` has-text-${align}` : "") +
      (className ? ` ${className}` : "")
    }
  >
    {children}
  </p>
);

const LinkText = ({ align, children, className }) => (
  <p
    className={cx(
      "text-link",
      align ? `has-text-${align}` : undefined,
      className
    )}
  >
    {children}
  </p>
);

const DropdownLinkText = ({ align, children, className }) => (
  <p
    className={cx(
      "text-link",
      align ? `has-text-${align}` : undefined,
      className
    )}
  >
    {children}
  </p>
);

const LinkTextTitle = ({ align, children, className, ...props }) => (
  <p
    className={cx(
      "text-link-title",
      align ? `has-text-${align}` : undefined,
      className
    )}
    {...props}
  >
    {children}
  </p>
);

const NumberHeading = ({ align, children, className, ...props }) => (
  <p
    className={cx(
      "text-number-heading",
      align ? `has-text-${align}` : undefined,
      className
    )}
    {...props}
  >
    {children}
  </p>
);

export {
  NumberHeading,
  HeroText,
  HeroTextSmall,
  TitleText,
  SecondaryTitleText,
  TertiaryTitleText,
  SubtitleText,
  TagText,
  ButtonText,
  SubmitButtonText,
  ParagraphText,
  ParagraphTextBook,
  SecondaryParagraphText,
  TertiaryParagraphText,
  LinkText,
  LinkTextTitle,
  DropdownLinkText,
};
