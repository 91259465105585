import React from "react";
import {
  TitleText,
  SecondaryParagraphText,
  SubtitleText,
  ParagraphText
} from "../../shared/typography/Typography";

import "./PrivacyPolicyBlock.scss";

const PrivacyPolicyBlock = () => (
  <div className="section privacy-policy">
    <div className="container">
      <TitleText align="centered" className="dusk-m-bot">
        Drinki Limited.
      </TitleText>

      <SubtitleText>UNITED KINGDOM</SubtitleText>

      <SecondaryParagraphText>
        Please read this privacy policy carefully as it sets out how DRINKI LTD
        (<span class="b">“DRINKI”</span> <span class="b">“we”</span> or{" "}
        <span class="b">“us”</span>) uses your personal information to provide
        you with the DRINKI mobile software application (the{" "}
        <span class="b">“App”</span>) and websites (together with the App,
        collectively the <span class="b">“Services”</span>). If you have any
        questions about this privacy policy, please contact us at 
        <a href="mailto:info@dusk.app">info@dusk.app</a>. We may change this
        policy from time to time so please check the the App or our website from
        time to time for any updates. If we make material changes in the way we
        use your personal information, we will notify you by posting a notice on
        the App and/or our website or sending you an email at the email address
        provided at the time of registration. Your continued use of any part of
        the Services following any update to this privacy policy will constitute
        your acceptance of any changes.
      </SecondaryParagraphText>

      <ParagraphText>1. ABOUT US</ParagraphText>

      <SecondaryParagraphText>
        1.1. We are DRINKI Limited, a company registered in England and Wales
        under company registration number 08704401 and have our registered
        office at 77 Leonard Street, London, England, EC2A 4QS
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        1.2 We are a platform that provides information and promotions on behalf
        of venues, bars and FMCG brand owners to our members (the{" "}
        <span class="b">“Partners”</span>).
      </SecondaryParagraphText>

      <ParagraphText>2. OVERVIEW</ParagraphText>

      <SecondaryParagraphText>
        2.1. DRINKI is a 'Data Controller' for the purposes of applicable data
        protection law.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        2.2. This privacy policy explains how we use your personal information
        and other data we collect when you use the Services.
      </SecondaryParagraphText>

      <ParagraphText>3. COLLECTED INFORMATION</ParagraphText>

      <SecondaryParagraphText>
        3.1. All personal information you provide to us when registering with
        the Services, and any personal information generated by your navigation
        and use of the Services, will be processed and stored by DRINKI to
        manage registration and provide the Service. We also use this
        information to analyse trends in our users' behaviour to help us to
        determine your preferences, habits, tastes, geographic location in order
        to maximise your use and enjoyment of the Services. We have further
        listed the ways in which we use your personal information at Clause 5
        below.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        3.2. The types of personal information we collect may include:
      </SecondaryParagraphText>

      <ul>
        <li>
          <SecondaryParagraphText>
            <span class="b">Personal details</span> – including your name, email
            address, phone number, postal address, date of birth, information
            regarding your use of the Services, mobile device information and
            browser information;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span class="b">Location data</span> – we may collect your IP
            address, and if you allow us to connect to your device’s geolocation
            functionality, we may collect your GPS data. We will use the
            location data to improve personalised recommendations in the
            Services on what venues are near you. You can turn off the App's
            ability to access your geolocation at any time through your device’s
            general or privacy settings;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span class="b">Your image or recordings</span> – we may obtain
            photographs, audio or video recordings of events listed on the
            Services. We may reproduce and/or publish your image in the App, our
            website, and in other promotional materials, social networking
            channels and other materials related to the Services;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span class="b">Your preferences</span> – we may collect information
            about your purchase history, venues you have viewed, attended or
            enquired about and any feedback you may give or reviews you may
            submit;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span class="b">Your interactions with us</span> – for example if
            you send us information via email, survey, social media, customer
            service communications or other methods, this may be stored on our
            systems;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            Technical information – when you use the Services, DRINKI
            automatically receives and records information on its server logs
            from your browser or mobile platform, including the location, IP
            address, cookie information and details about the page you
            requested.
          </SecondaryParagraphText>
        </li>
      </ul>

      <ParagraphText>4. COOKIES</ParagraphText>

      <SecondaryParagraphText>
        4.1. Our website uses cookies to distinguish you from other users of our
        website. This helps us to provide you with a good experience when you
        browse our website and also allows us to improve our site. A cookie is a
        small file of letters and numbers that we store on your browser or the
        hard drive of your device if you agree. Cookies contain information that
        is transferred to your device hard drive.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        4.2. Our App does not use cookies.
      </SecondaryParagraphText>

      <ul>
        <li>
          <SecondaryParagraphText>
            <span class="b">Strictly necessary cookies.</span> These are cookies
            that are required for the operation of our website. They include,
            for example, cookies that enable you to log into secure areas of our
            website, use a shopping cart or make use of e-billing services.
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span class="b">Analytical/performance cookies.</span> They allow us
            to recognise and count the number of visitors and to see how
            visitors move around our website when they are using it. This helps
            us to improve the way our website works, for example, by ensuring
            that users are finding what they are looking for easily.
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span class="b">Functionality cookies.</span> These are used to
            recognise you when you return to our website. This enables us to
            personalise our content for you, greet you by name and remember your
            preferences (for example, your choice of language or region).
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span class="b">Targeting cookies.</span> These cookies record your
            visit to our website, the pages you have visited and the links you
            have followed. We will use this information to make our website and
            the advertising displayed on it more relevant to your interests. We
            may also share this information with third parties for this purpose.
          </SecondaryParagraphText>
        </li>
      </ul>

      <SecondaryParagraphText>
        4.4. You can block cookies by activating the setting on your browser
        that allows you to refuse the setting of all or some cookies. However,
        if you use your browser settings to block all cookies (including
        essential cookies) you may not be able to access all or parts of our
        site.
      </SecondaryParagraphText>

      <ParagraphText>5. USE OF YOUR PERSONAL INFORMATION</ParagraphText>

      <SecondaryParagraphText>
        5.1. As well as the purposes described above at Clause 3.1, we use the
        personal information we collect to:
      </SecondaryParagraphText>

      <ul>
        <li>
          <SecondaryParagraphText>
            administer the App and the Services or any other purposes required
            in order to allow us to provide the Services;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            communicate with you via email, SMS, in-App notifications and push
            notifications about the Services;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            provide better content, services, marketing, and support to you. For
            example, we may need to use your information to administer your
            account or provide you with information in connection with promotion
            that we think you will be interested in;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            administer and fulfil any competitions available to users via the
            Services and communicate with you about those competitions. Note
            that there may be specific terms in the competition terms and
            conditions relating to your personal data; and
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            to profile individual and demographic trends of people attending or
            showing an interest in certain venues or drinks. This helps us to
            provide you with recommendations of similar things you might like or
            find interesting. We do this by making assumptions based on an
            analysis of the information we hold, including the venues you have
            viewed and opinions you have submitted.
          </SecondaryParagraphText>
        </li>
      </ul>

      <SecondaryParagraphText>
        5.2. We also use information that we collect about you to for
        operational purposes, including:
      </SecondaryParagraphText>

      <ul>
        <li>
          <SecondaryParagraphText>
            to provide customer service, including to respond to your enquiries
            and fulfil any of your requests for information;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            carry out verification checks, including to verify your identity, to
            ensure to the best of our ability that all users are on drinking
            age;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            to send you important information regarding the Services, or other
            technical notices, updates, security alerts, and support and
            administrative messages; and
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            as we believe to be necessary or appropriate under applicable law,
            to enforce our rights or to respond to requests from law enforcement
            and other government authorities.
          </SecondaryParagraphText>
        </li>
      </ul>

      <SecondaryParagraphText>
        5.3. We use your personal data on the following legal basis:
      </SecondaryParagraphText>

      <ul>
        <li>
          <SecondaryParagraphText>your consent;</SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            as necessary to perform a contract to which you are a party;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            in our legitimate interests, including to improve the performance of
            the Services and in order to enhance our users’ experience of the
            Services, or in an Event Partner’s legitimate interests; and/or
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            to comply with a legal obligation.
          </SecondaryParagraphText>
        </li>
      </ul>

      <SecondaryParagraphText>
        5.4. Where we rely on your consent to process your personal data (e.g.
        marketing), you may withdraw your consent at any time by emailing us
        at info@drinki.com or via methods set out in this privacy policy. If you
        withdraw your consent, this will not affect the lawfulness of any
        processing which has taken place before you have withdrawn your consent.
      </SecondaryParagraphText>

      <ParagraphText>6. MARKETING</ParagraphText>

      <SecondaryParagraphText>
        6.1. We may send you marketing communications via email and push
        notifications. You are given the opportunity to opt in to marketing
        communications and push notifications on registration. If you change
        your mind and do not want to receive any marketing emails from us, you
        can unsubscribe from emails by following the unsubscribe directions
        provided in the footer of each one. If you do not want to receive push
        notifications from us you may opt out by changing the appropriate
        settings directly on your mobile device.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        6.2. Where you opt out of receiving these marketing communications, we
        may still process your personal data for other required purposes, as
        specified in Clauses 4 and 5 above.
      </SecondaryParagraphText>

      <ParagraphText>7. DISCLOSURE OF YOUR INFORMATION</ParagraphText>

      <SecondaryParagraphText>
        7.1. We do not sell your personal information to anyone without your
        explicit consent, but we may share your personal information in the ways
        described below:
      </SecondaryParagraphText>

      <ul>
        <li>
          <SecondaryParagraphText>
            <span className="b">Agents and suppliers</span> – We employ other
            companies and individuals to perform functions on our behalf,
            including our payment processing suppliers and data analytics
            services providers. We do not share your personal information with
            our agents or suppliers except as is necessary in order to enable
            them to provide us with a service.
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span className="b">Third Parties</span> – We may integrate with
            other third party products and services such as FACEBOOK to enable
            certain features of the Services. We do not share your personal
            information with them except as is necessary in order to allow them
            to perform the features requiring their integration.
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span className="b">Competition partners</span> – When offering
            competitions via the Services, we may share your personal
            information with our competition partner for the purpose of
            administering the competition (for example, arranging for the prize
            to be provided to the winner).
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            <span className="b">Other reasons</span> – We may also share your
            personal information with a purchaser or potential purchaser of our
            business and in some circumstances, we may have to disclose your
            personal information by law, either because a court or the police or
            other law enforcement agency has asked us for it, to enforce our
            legal rights.
          </SecondaryParagraphText>
        </li>
      </ul>

      <SecondaryParagraphText>
        7.2. We may also provide our partners with anonymous information about
        how users, collectively, use the App and website so that they can
        understand how often people use their services, the App and the website.
      </SecondaryParagraphText>

      <ParagraphText>
        8. SECURITY AND INTERNATIONAL TRANSFERS OF DATA
      </ParagraphText>

      <SecondaryParagraphText>
        8.1 We are committed to ensuring that your information will be treated
        confidentially and securely, and we have put in place appropriate
        measures to ensure the security of your personal data.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        8.2 The personal data that we collect from you may be transferred to,
        and stored at, destinations outside the European Economic Area (EEA)
        where the laws on processing personal data may be less stringent than in
        your country. It may also be processed by staff operating outside the
        EEA who work for us or for one of our agents or suppliers.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        8.3 Presently personal data collected from you is stored in data centres
        as well as cloud networks.
      </SecondaryParagraphText>
      <SecondaryParagraphText>
        8.4 When your personal data is transferred to parties located outside of
        the EEA we shall undertake an assessment and take appropriate measures
        to ensure such third party will provide adequate security of such
        personal data and respect your rights to privacy. These safeguards are
        intended to ensure a similar degree of protection is afforded to your
        data wherever it may be transferred and may include:
      </SecondaryParagraphText>

      <ul>
        <li>
          <SecondaryParagraphText>
            only transferring your personal data to countries which have been
            deemed to provide an adequate level of protection for personal data
            by the European Commission;
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            entering into specific contractual terms which have been approved by
            the European Commission and which give personal data the same
            protection as within the EEA; or
          </SecondaryParagraphText>
        </li>
        <li>
          <SecondaryParagraphText>
            where your data will be transferred to the US, ensuring that the
            third party to which we are transferring your data is part of the
            Privacy Shield.
          </SecondaryParagraphText>
        </li>
      </ul>

      <SecondaryParagraphText>
        8.5 Although we take all steps reasonably necessary to ensure that your
        personal data is treated securely, you should be aware that the
        transmission of information via the internet is not completely secure
        and we cannot guarantee the security of your data during its
        transmission.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        8.6 We may retain your personal data for as long as we require for the
        purposes for which it is processed or as is otherwise required by
        applicable law. If you terminate your account on the App and website,
        cease using the Services, or our agreement with you under the Terms of
        Use is terminated, we may continue to process your personal data for a
        reasonable period and as required under applicable law.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        8.7 When calculating the appropriate retention period for your data, we
        consider the nature and sensitivity of the data, the purposes for which
        we are processing the data, and any applicable statutory retention
        periods. Using these criteria, we regularly review the personal data
        which we hold and the purposes for which it is held and processed. Once
        processing has been completed, we may securely delete or anonymise your
        personal data.
      </SecondaryParagraphText>

      <ParagraphText>9. YOUR RIGHTS</ParagraphText>

      <SecondaryParagraphText>
        9.1. You may request details of the personal information that we hold
        about you under data protection laws. If you would like a copy of the
        information held about you please write to us at 
        <a href="mailto:info@dusk.app">info@dusk.app</a>.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        9.2. If you think any information we have about you is incorrect or
        incomplete, please write to us or email us at 
        <a href="mailto:info@dusk.app">info@dusk.app</a> as soon as possible. We
        will correct or update any information as soon as we can.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        9.3 Under the EU General Data Protection Regulation and any equivalent
        rules in the UK, you are entitled to certain rights in relation to our
        handling of your personal data. For example, will have the right to have
        your personal data rectified or erased, the right to object to or
        restrict us from processing your personal data (including the right to
        object to profiling), and the right to data portability. If you would
        like to exercise these rights once they come into force, please write to
        us or email us at <a href="mailto:info@dusk.app">info@dusk.app</a>.
      </SecondaryParagraphText>

      <SecondaryParagraphText>
        9.4. Should you have any complaints about how we handle your personal
        information, please contact us at 
        <a href="mailto:info@dusk.app">info@dusk.app</a>. Should we be unable to
        resolve your complaint and you wish to take your complaint further, you
        may do so by contacting the UK Information Commissioner’s Office.
      </SecondaryParagraphText>
    </div>
  </div>
);

export default PrivacyPolicyBlock;
