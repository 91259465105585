import React, { useState } from "react";
import cx from "classnames";

import { NavLink } from "react-router-dom";

import "./Header.scss";
import { TabletOnwards, UpToTablet } from "shared/MediaQuery";

const Links = {
  workWithUs: [
    { title: "VENUES", to: "/for-bars" },
    { title: "DRINKS BRANDS", to: "/for-brands" },
    { title: "REWARDS PARTNERS", to: "/for-rewards" },
    { title: "DUSK ACCELERATOR", to: "/dusk-accelerator" },
  ],
};

const MobileNavItemBasic = ({ link, children, ...props }) => (
  <NavLink to={link}>
    <p
      className="dusk-condensed-p-30 has-text-white pl-3 pt-2 pb-1 pr-2 dusk-link-item-hover"
      {...props}
    >
      {children}
    </p>
  </NavLink>
);

const MobileNavItem = ({ link, children, ...props }) => (
  <p
    className="dusk-link-16 has-text-white has-text-centered dusk-link-item"
    {...props}
  >
    <NavLink to={link}>{children}</NavLink>
  </p>
);

const MobileNavTitle = ({ children, ...props }) => (
  <p className="has-text-centered dusk-link-title-16 dusk-link-item" {...props}>
    {children}
  </p>
);

const MobileNavItemOutline = ({ link, children, ...props }) => (
  <p className="has-text-centered dusk-link-item" {...props}>
    <NavLink className="dusk-link-title-16" to={link}>
      {children}
    </NavLink>
  </p>
);

const DesktopNavItemLink = ({ link, children, ...props }) => (
  <p
    className="has-text-centered level-item mx-2 is-align-items-flex-start pt-2"
    {...props}
  >
    <NavLink className="dusk-condensed-p-30 dusk-link-item-hover" to={link}>
      {children}
    </NavLink>
  </p>
);

const DesktopNavItem = ({ link, children, ...props }) => (
  <p
    className="has-text-centered dusk-condensed-p-30 mx-2 is-pointer-click dusk-link-item-hover pt-2"
    {...props}
  >
    {children}
  </p>
);

const DesktopDropDownNavItem = ({ link, children, ...props }) => (
  <NavLink className="dusk-link-item-hover" to={link}>
    <TabletOnwards>
      <p
        className="dusk-condensed-p-20 has-text-white has-text-centered dusk-link-item-desktop"
        {...props}
      >
        {children}
      </p>
    </TabletOnwards>
    <UpToTablet>
      <p
        className="dusk-condensed-p-16 has-text-white has-text-centered dusk-link-item-desktop"
        {...props}
      >
        {children}
      </p>
    </UpToTablet>
  </NavLink>
);

const DesktopHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = e => {
    setIsOpen(false);
  };

  return (
    <section className="section is-hidden-mobile py-2">
      <div className="container">
        <div className="level is-align-items-flex-start">
          <MobileNavItemBasic link="/" onClick={handleClose}>
            DUSK
          </MobileNavItemBasic>
          <div className="level-right is-align-items-flex-start">
            <DesktopNavItem>
              <a
                className="dusk-condensed-p-30 dusk-link-item-hover"
                href="https://blog.dusk.app/"
                target="_blank"
                rel="noreferrer"
              >
                GUIDES
              </a>
            </DesktopNavItem>
            <div className="level-item dusk-dropdown-parent is-align-items-flex-start">
              <div>
                <DesktopNavItem onMouseOver={() => setIsOpen(true)}>
                  WORK WITH US
                </DesktopNavItem>
                <div
                  className={cx(
                    "dusk-open-nav",
                    isOpen ? "is-open" : undefined
                  )}
                  onClick={handleClose}
                />
                <div className="dusk-dropdown-child">
                  {Links.workWithUs.map(each => (
                    <DesktopDropDownNavItem
                      key={each.to}
                      link={each.to}
                      onClick={handleClose}
                    >
                      {each.title}
                    </DesktopDropDownNavItem>
                  ))}
                </div>
              </div>
            </div>
            <DesktopNavItemLink link="/support" onClick={handleClose}>
              SUPPORT
            </DesktopNavItemLink>
          </div>
        </div>
      </div>
    </section>
  );
};

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <nav
      className="navbar is-hidden-tablet"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <MobileNavItemBasic link="/" onClick={handleClose}>
          DUSK
        </MobileNavItemBasic>

        <span
          role="button"
          onClick={() => setIsOpen(!isOpen)}
          className={cx(
            "navbar-burger burger",
            isOpen ? "is-active" : undefined
          )}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar-content"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </span>
      </div>

      <div
        id="navbar-content"
        className={cx("navbar-menu", isOpen ? "is-active" : undefined)}
      >
        <div className="navbar-start dusk-mobile-navbar">
          <p className="dusk-link-16 has-text-white has-text-centered dusk-link-item">
            <a href="https://blog.dusk.app/" target="_blank" rel="noreferrer">
              GUIDES
            </a>
          </p>
          <MobileNavTitle>WORK WITH US</MobileNavTitle>
          {Links.workWithUs.map(each => (
            <MobileNavItem key={each.to} link={each.to} onClick={handleClose}>
              {each.title}
            </MobileNavItem>
          ))}
          <MobileNavItemOutline link="/support" onClick={handleClose}>
            SUPPORT
          </MobileNavItemOutline>
        </div>

        <div className="navbar-end" />
      </div>
    </nav>
  );
};

const Header = () => (
  <>
    <MobileHeader />
    <DesktopHeader />
  </>
);

export default Header;
