import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router } from "react-router-dom";

import constants from "./constants";

import App from "./App";
import ScrollToTop from "./shared/ScrollToTop";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

const tagManagerArgs = {
  gtmId: constants.gtm,
};

if (constants.gtm) {
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <Router>
    <Helmet>
      <title>DUSK - The Free Drink App</title>
      <meta property="og:title" content="DUSK - The Free Drink App" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="DUSK - The Free Drink App" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
