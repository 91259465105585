import React, { Fragment } from "react";
import Image from "../../shared/Image";
import { SecondaryParagraphText } from "../../shared/typography/Typography";
import "./ImageForBar.scss";

const placeholder = [
  require("../../assets/images/heroBarSecondary.jpg"),
  require("../../assets/images/heroBarSecondary@2x.jpg"),
  require("../../assets/images/heroBarSecondary@3x.jpg")
];

const ImageForBar = ({ images, address }) => (
  <Fragment>
    <Image
      className={`recommended-bar-image ${!images ? "blur" : ""}`}
      srcs={images ? images : placeholder}
      alt={address}
    />
    <SecondaryParagraphText className="recommended-bar-text" align="left">
      {address}
    </SecondaryParagraphText>
  </Fragment>
);

export default ImageForBar;
