import React from "react";

const inputTypes = {
  email: {
    placeholder: "",
    // label: "Email Address",
    type: "email",
  },
  phone: {
    placeholder: "",
    // label: "Phone Number",
    type: "tel",
  },
  name: {
    // label: "Full Name",
    placeholder: "",
  },
};

const TrackingInputField = ({ children, className, inputType, ...props }) => (
  <div className={"field track-field" + (className ? ` ${className}` : "")}>
    {children}
    <div className="control">
      <input
        className="input"
        type={inputTypes[inputType].type ? inputTypes[inputType].type : "text"}
        placeholder={inputTypes[inputType].placeholder}
        {...props}
      />
    </div>
  </div>
);

export default TrackingInputField;
