import React, { PureComponent } from "react";
import cx from "classnames";
import Columns from "../../shared/Columns";
import SubmitButton from "../../shared/SubmitButton";
// import { ParagraphText } from "../../shared/typography/Typography";
import { InputField } from "../../shared/Input";
import { validateEmail, apiCallContact } from "../../shared/utils";

class RequestOurDeck extends PureComponent {
  state = {
    company: "",
    name: "",
    email: "",
    number: "",
    error: null,
    sending: false,
    sent: false,
  };

  _companyChanged = (evt) => {
    this.setState({
      company: evt.target.value,
    });
  };

  _nameChanged = (evt) => {
    this.setState({
      name: evt.target.value,
    });
  };

  _emailChanged = (evt) => {
    this.setState({
      email: evt.target.value,
    });
  };

  _numberChanged = (evt) => {
    this.setState({
      number: evt.target.value,
    });
  };

  _sendForm = (company, name, email, number) => {
    const payload = {
      company,
      name,
      email,
      phone: number,
      type: "download-deck",
    };

    apiCallContact(payload).then(
      () => {
        this.setState({
          company: "",
          name: "",
          email: "",
          number: "",
          sending: false,
          sent: true,
        });
      },
      () => {
        this.setState({
          sending: false,
          sent: false,
          error: "An unknown error has occurred. Please try again later.",
        });
      }
    );
  };

  _sendClicked = () => {
    const { company, name, email, number, sending, sent } = this.state;

    if (sending || sent) return;

    if (!company) {
      this.setState({ error: "Please enter the name of your brand." });
      return;
    }

    if (!name) {
      this.setState({ error: "Please enter your name." });
      return;
    }

    if (!email || !validateEmail(email)) {
      this.setState({ error: "Please enter a valid email." });
      return;
    }

    if (!number) {
      this.setState({
        error: "Please enter a phone number for us to contact you on.",
      });
      return;
    }

    // Form is valid, thunderbirds are go!
    this.setState({
      sending: true,
      error: null,
    });

    this._sendForm(company, name, email, number);
  };

  render() {
    const { innerRef } = this.props;
    const { company, name, email, number, error, sending, sent } = this.state;

    var className = "";
    if (sending) {
      className = "is-loading";
    } else if (sent) {
      className = "is-success";
    }

    var buttonText = "Get in touch";
    if (sending) {
      buttonText = "";
    } else if (sent) {
      buttonText = "Sent";
    }

    return (
      <Columns>
        <div
          ref={innerRef}
          className="column is-offset-one-fifth is-three-fifths has-text-centered"
        >
          <div className="mx-3">
            <h1 className="dusk-h1-outline-80 mb-2">REQUEST OUR DECK</h1>
            <p className="dusk-p-16 mb-2">
              Sign up for more details and our onboarding pack.
            </p>
            <InputField
              inputType="company"
              value={company}
              onChange={this._companyChanged}
            />
            <InputField
              inputType="name"
              value={name}
              onChange={this._nameChanged}
            />
            <InputField
              inputType="email"
              value={email}
              onChange={this._emailChanged}
            />
            <InputField
              className={error ? "" : "dusk-m-bot"}
              inputType="phone"
              value={number}
              onChange={this._numberChanged}
            />
            {error ? (
              <p className="dusk-p-error-14 my-2 has-text-left is-flex align-items-center justify-content-center">
                <img
                  className="mr-3"
                  width={18}
                  height={18}
                  src={require("assets/images/inputIconError.svg")}
                  alt="warning sign"
                />
                {error}
              </p>
            ) : null}
            <div className="is-hidden-tablet">
              <SubmitButton
                small
                className={cx("is-fullwidth", className)}
                onClick={this._sendClicked}
              >
                {buttonText}
              </SubmitButton>
            </div>
            <div className="is-hidden-mobile">
              <SubmitButton
                className={cx("is-fullwidth", className)}
                onClick={this._sendClicked}
              >
                {buttonText}
              </SubmitButton>
            </div>
          </div>
        </div>
      </Columns>
    );
  }
}

export default RequestOurDeck;
